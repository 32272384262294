<template>
  <v-footer class="pa-0" dark height="auto">
    <div class="appBarColor" style="width: 100%">
      <marquee
        behavior=""
        :direction="$i18n.locale == 'ar' ? 'right' : 'left'"
        scrolldelay="200"
        class="caption appBarColor py-2"
        style="width: 100%"
        onmouseover="this.stop()"
        onmouseout="this.start()"
      >
        <div class="" v-if="commodities.length">
          <span v-for="commodity in commodities" class="mx-4">
            <strong>{{ commodity.product }}</strong>
            <span class="mx-2">
              <span>{{
                currencyFormat(commodity.price) + " / " + commodity.unit
              }}</span>
              <v-icon
                small
                right
                class="mb-1"
                :color="
                  getSign(commodity) ? 'success darken-1' : 'error darken-2'
                "
                >{{
                  getSign(commodity)
                    ? "mdi-arrow-up-bold"
                    : "mdi-arrow-down-bold"
                }}</v-icon
              >
              <span> {{ getDifference(commodity) }}</span>
            </span>
          </span>
        </div>
        <div class="" v-else>
          <span>{{ $t("products update") }}</span>
        </div>
      </marquee>
    </div>

    <div>
      <GoogleMap v-if="$route.name == 'Landing'" />
      <FooterContent />
      <v-layout
        row
        wrap
        class="ma-auto primary py-3"
        justify-space-between
        align-center
      >
        <v-flex
          xs12
          sm12
          md4
          lg4
          xl4
          class="caption d-flex px-3"
          :class="`${
            $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-start'
          }`"
        >
          <span class="px-0"
            >&copy; {{ new Date().getFullYear() }} - {{ $t("copyright") }}</span
          >
        </v-flex>
        <v-flex xs12 sm12 md8 lg8 xl8>
          <div
            class="caption d-flex"
            :class="`${
              $vuetify.breakpoint.smAndDown
                ? 'justify-center pt-2'
                : 'justify-end px-3'
            }`"
          >
            <a
              @click="redirect(policy.id)"
              style="text-decoration: none"
              class="white--text d-flex text-center"
              v-for="(policy, i) in policies.slice(0, 3)"
              :key="i"
              >{{ policy.title }}

              <v-divider vertical class="mx-4" v-if="i < 2"></v-divider>
            </a>
          </div>
        </v-flex>
      </v-layout>
      <v-divider class=""></v-divider>
      <v-layout
        row
        wrap
        class="ma-auto py-3 pt-2 primary"
        justify-space-between
        align-center
      >
        <v-flex
          xs12
          sm12
          md6
          lg6
          xl6
          :class="`px-3 ${
            $vuetify.breakpoint.smAndDown
              ? 'text-center'
              : $i18n.locale == 'ar'
              ? 'text-right'
              : 'text-left'
          }`"
        >
          <div class="caption">
            <span>{{ $t("powered by") }}</span>
            <a
              href="https://lilicoengineering.com/"
              target="_blank"
              class="orange--text"
            >
              Lilico
            </a>
          </div>
        </v-flex>
        <v-flex
          xs12
          sm12
          md6
          lg6
          xl6
          :class="`px-3 ${
            $vuetify.breakpoint.smAndDown
              ? 'text-center'
              : $i18n.locale == 'ar'
              ? 'text-left'
              : 'text-right'
          }`"
        >
          <div class="caption">
            <span>{{ $t("designed and developed by") }}</span>
            <a
              href="https://mijinitech.com/index.php/en/"
              target="_blank"
              class="orange--text"
            >
              MijiniTech
            </a>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </v-footer>
</template>

<script>
import functions from "@/utilities/functions";
import GoogleMap from "./googleMap.vue";
import FooterContent from "../footer/footerContent.vue";

export default {
  name: "Footer",
  data() {
    return {
      links: [],
      list: [],
      policies: [
        { name: "Guide to Authors", id: "articles" },
        { name: "Copyrights", id: "news" },
        { name: "Data Protection", id: "news" },
      ],
      price: [],
    };
  },
  computed: {
    commodities() {
      return this.list.sort((a, b) => (a.date < b.date ? 1 : -1)) || [];
    },
  },
  methods: {
    ...functions,
    redirect(policyId) {
      console.log(policyId);
      this.$router
        .push({
          name: "Privacy",
          params: {
            id: policyId,
          },
        })
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    async getData() {
      this.prices = await this.$store.dispatch("GET_PRODUCT_PRICINGS");
      this.policies = await this.$store.dispatch("GET_POLICIES");
      this.list = this.prices.filter(
        (val) =>
          this.dateFormat_short(val.date) == this.dateFormat_short(new Date())
      );
      // this.getDifference();
    },
    getDifference(todayPrice) {
      let prod = this.prices.filter(
        (val) => val.product.toLowerCase() == todayPrice?.product.toLowerCase()
      );
      let date = this.dateFormat_short(
        new Date().setDate(new Date().getDate() - 1)
      );
      let yesterdayPrice = prod.find(
        (val) => this.dateFormat_short(val.date) == date
      );
      let difference = Math.abs(
        Number(yesterdayPrice?.price ?? 0) - Number(todayPrice?.price)
      );

      let averagePrice =
        (Number(yesterdayPrice?.price ?? 0) + Number(todayPrice?.price)) / 2;
      return ` ${((difference / averagePrice) * 100).toFixed(2)}%`;
    },
    getSign(todayPrice) {
      let prod = this.prices.filter(
        (val) => val.product.toLowerCase() == todayPrice?.product.toLowerCase()
      );
      let date = this.dateFormat_short(
        new Date().setDate(new Date().getDate() - 1)
      );
      let yesterdayPrice = prod.find(
        (val) => this.dateFormat_short(val.date) == date
      );

      return todayPrice?.price >= (yesterdayPrice?.price ?? 0);
    },
  },
  components: { GoogleMap, FooterContent },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.v-footer {
  background-color: rgb(29, 131, 151) !important;
}
marquee,
.scroller-container {
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}
.scroller-container {
  /* min-height: 100px; */
  width: 100%;
}
.scroller {
  width: 100%;
  top: 0;
  left: 0;
  white-space: nowrap;
  animation: bannermove 20s linear infinite;
}
.scroller:hover {
  animation-play-state: paused;
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
</style>
