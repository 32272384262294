import axios from "axios";
// import getters from '@/store/getters'

// axios.defaults.headers["Accept-Language"] = localStorage.getItem("lang");

var base = `${process.env.VUE_APP_BASE_URL}/post/`;

// baseURL: process.env.VUE_APP_API,
const instance = axios.create({
  baseURL: base,
});

export default instance;
