import i18n from "@/plugins/i18n";
import jwt from "jwt-decode";

export default {
  //SETUPS
  getDomain: (state) => state.domain,

  //SERVICES

  getCurrentUser: (state) => state.currentUser.response_data,
  getMenuList: (state) => state.menuList,
  getLoader: (state) => state.loader,
  getToken: (state) => state.currentUser.access_token,
  getRefreshToken: (state) => state.currentUser.refresh_token,
  getSnackbar: (state) => state.snackbar,
  getProgress: (state) => state.progress,

  //**********MANIPULATIONS************** */

  registeredPages(state) {
    var pg = state.pages.sort((a, b) => (a.title > b.title ? 1 : -1));
    pg.forEach((val) => (val.selected = false));
    return pg;
  },

  underMaintenance: (state) => state.system_under_maintenance,

  getMobileDrawer: (state) => state.mobileDrawer,
  webMainLinks: (state) => state.websiteMenus.menus,
  webProfileLinks: (state) => state.websiteMenus.profile,
  topAppBar: (state) => state.topAppBar,
  getError: (state) => state.error,
  getJobTypes: (state) => state.jobTypes,
  getJobs: (state) => state.jobs,
  getEventTypes: (state) => state.eventTypes,
  getEvents: (state) => state.events,
  getNewsTypes: (state) => state.newsTypes,
  getNews: (state) => state.news,
  getResearches: (state) => state.researches,
  getSingleNews: (state) => state.singleNews,
  getCategories: (state) => state.categories,
  getCommodities: (state) => state.commodities,
  getProducts: (state) => state.products,
  getRoles: (state) => state.roles,
  getUsers: (state) => state.users,
  getCountries: (state) => state.countries,
  getRadioTamazujNews: (state) => state.scraped.radioTamazuj,
  getTopNews: (state) => state.scraped.topNews,
  getSudanTribune: (state) => state.scraped.sudanTribune,
  getMiningTechnology: (state) => state.scraped.miningTechnology,
  getAllNews: (state) => state.allNews,
  getSources: (state) => state.sources,
  getSubCategories: (state) => state.subCategories,
  getcomment: (state) => state.comment,
  getLanguage() {
    return i18n.locale == "en"
      ? "English"
      : i18n.locale == "fr"
      ? "French"
      : "Arabic";
  },
  getTextDirection() {
    return i18n.locale == "ar" ? "rtl" : "ltr";
  },
};
