const animationScrollObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('enter')
      animationScrollObserver.unobserve(entry.target)
    }
  })
})
export default {
  bind (el) {
    el.classList.add('before-enter')
    animationScrollObserver.observe(el)
  }
}
