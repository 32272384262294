import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const getBrowserLocales = () => {
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;
  if (!browserLocales) {
    return ["en"];
  }
  return browserLocales.map((locale) => {
    return new Intl.Locale(locale).language;
    // const trimmedLocale = locale.trim();
    // return trimmedLocale.split(/-|_/)[0];
  });
};
const userPreferedLanguage = getBrowserLocales()[0];
const i18n = new VueI18n({
  locale: userPreferedLanguage, // set locale
  fallbackLocale: "en", // set fallback locale
  messages: loadLocaleMessages(), // set locale messages
});

export default i18n;
