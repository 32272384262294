<template>
  <div class="text-h6 left-title">
    <h3>
      <b
        class="font-weight-bold main-post-title text-uppercase"
        :dir="$store.getters.getTextDirection"
        >{{ title }}
        <hr class="cat-post-title" />
      </b>
    </h3>
  </div>
</template>

<script>
export default {
  name: "TitleModel",
  props: ["title", "main"],
};
</script>

<style></style>
