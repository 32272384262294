import store from './../store'
import { format } from 'date-fns'
import converter from 'number-to-words'

function digits (event) {
  let keyCode = event.keyCode ? event.keyCode : event.which
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    // 46 is dot
    event.preventDefault()
  }
}

function makeUpperCase (event) {
  event.target.value = event.target.value.toUpperCase()
}

function getAge (date) {
  let today = new Date()
  let birthday = new Date(date)
  let age = today.getFullYear() - birthday.getFullYear()
  let m = today.getMonth() - birthday.getMonth()
  if (m < 0 || (m == 0 && today.getDate() < birthday.getDate())) {
    age = age - 1
  }
  return age
}

function getFullName (names) {
  return names.surName + ' ' + names.otherNames
}

function AssetImage (dir, image) {
  if (dir == '') return require('@/assets/' + image)
  return require('@/assets/' + dir + '/' + image)
}

function numberFormat (val) {
  var decimalCount = 1
  var decimal = '.'
  var thousands = ','
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount

  const negativeSign = val < 0 ? '-' : ''
  let i = parseInt(
    (val = Math.abs(Number(val) || 0).toFixed(decimalCount))
  ).toString()
  let j = i.length > 3 ? i.length % 3 : 0

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(val - i)
          .toFixed(decimalCount)
          .slice(2)
      : '')
  )
}

function currencyFormat (val) {
  val = val != null ? val : 0
  return store.state.currency + ' ' + numberFormat(val)
}

function dateFormat_short (date) {
  let d = date ? format(new Date(date), 'yyyy-MM-dd') : date
  return d
}

function dateFormat_long (date) {
  let d = date != '' ? format(new Date(date), 'eee dd MMMM yyyy') : date
  return d
}

function dateFormat_Month (date) {
  let d = date != '' ? format(new Date(date), 'MMMM dd, yyyy') : date
  return d
}

function dateFormat_Time (date) {
  let d = date != '' ? format(new Date(date), 'E, dd/MM/yyyy hh:mm a') : date
  return d
}

function convertingToWord (amount) {
  var word = converter.toWords(amount) // english
  var amountInLetter = word.toString().toUpperCase()

  return amountInLetter
}

function setFocus (div) {
  let input = document.getElementById(div)
  input.focus()
  input.select()
}

function monthYear (date) {
  var myear = date ? format(new Date(date), 'MMMM yyyy') : date
  return myear
}

function minAge (date) {
  let today = new Date()
  let birthday = new Date(date)
  let age = today.getFullYear() - birthday.getFullYear()
  let m = today.getMonth() - birthday.getMonth()
  if (m < 0 || (m == 0 && today.getDate() < birthday.getDate())) {
    age = age - 1
  }
  if (age < 16) {
    return false
  } else {
    return true
  }
}

function imageToDataUrl (src, callback) {
  let img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = function () {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    canvas.width = this.naturalWidth
    canvas.height = this.naturalHeight
    ctx.drawImage(this, 0, 0)
    let dataUrl = canvas.toDataURL('image/png')
    callback(dataUrl)
  }
  img.src = src
}

export default {
  digits,
  makeUpperCase,
  imageToDataUrl,
  dateFormat_short,
  dateFormat_long,
  dateFormat_Month,
  dateFormat_Time,
  currencyFormat,
  numberFormat,
  convertingToWord,
  AssetImage,
  getAge,
  getFullName,
  setFocus,
  monthYear,
  minAge
}
