import axios from '@/plugins/axios/scrapedNewsApi'

export default {
  GET_RADIO_TAMAZUJ_NEWS: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    // commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.get('scrapeRadiotamazujNews', config)
      commit('SET_LOADER', false)
      commit('SET_RADIO_TAMAZUJ', response.data.allNews)
      // console.log(response);
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      // commit('SNACKBAR', {
      //     snackbar: true,
      //     message: error.response.data.message,
      //     type: 'error'
      // })
      console.log(error)
    }
  },
  GET_TOP_NEWS: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.get('scrapeTopNews', config)
      commit('SET_LOADER', false)
      commit('SET_TOP_NEWS', response.data.allNews)
      // console.log(response);
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  GET_SUDAN_TRIBUNE: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.get('scrapeSudanTribune', config)
      commit('SET_LOADER', false)
      commit('SET_SUDAN_TRIBUNE', response.data.allNews)
      // console.log(response);
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  GET_MINING_TECHNOLOGY: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.get('scrapeMiningTechnology', config)
      commit('SET_LOADER', false)
      commit('SET_MINING_TECHNOLOGY', response.data.allNews)
      // console.log(response);
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  }
}
