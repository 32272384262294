<template>
  <div class="caption">
    <!-- <gmap-map
      :zoom="16"
      :center="center"
      style="width: 100%; height: 400px; font-size: 0.7rem !important"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in locationMarkers"
        :position="m.position"
        @click="center = m.position"
      ></gmap-marker>
    </gmap-map> -->
    <iframe
      width="100%"
      height="450"
      style="border: 0"
      loading="lazy"
      allowfullscreen
      referrerpolicy="no-referrer-when-downgrade"
      :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyDVygkMvXf52_ayQWgsn5ECFerpRkIEivY
    &q=Juba+Mall+Supermaket,Juba`"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      center: {
        // lat: 4.852729534184712,
        // lng: 31.612551247594478,
        lat: 4.854032,
        lng: 31.611633,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
    };
  },

  mounted() {
    // this.locateGeoLocation();
  },

  methods: {
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
  },
};
</script>
