<template>
  <div class="">
    <v-layout
      class="py-1 primary ma-auto"
      align-center
      justify-space-between
      row
      wrap
    >
      <v-flex class="d-flex justify-space-between align-center py-0 px-2">
        <lang-switcher />
        <div class="d-flex align-center">
          <v-btn
            icon
            v-for="(link, i) in altLinks"
            :key="i"
            class="mx-1"
            small
            @click="open(link)"
          >
            <!-- <v-icon small>{{ link.icon }}</v-icon> -->
            <Icon :icon="link.icon" width="15" />
          </v-btn>
          <Social />
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import LangSwitcher from "../core/lang-switcher.vue";
import { mapGetters } from "vuex";
import Social from "../models/social.vue";
// import Social from "../models/social.vue";
import { Icon } from "@iconify/vue2";

export default {
  name: "UpperAppBar",
  components: { Social, LangSwitcher, Icon },
  data() {
    return {
      altLinks: [
        {
          text: "+211 912 325 210",
          icon: "ion:call",
          show: false,
          value: "+211912325210",
        },
        {
          text: "ssmj@lilicoengineering.com",
          icon: "line-md:email",
          show: false,
          title: "email",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),
    isLoggedIn() {
      return this.currentUser?.fullName ? true : false;
    },
  },
  methods: {
    scroll() {
      document.getElementById("contactCard").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    redirect() {
      this.$router
        .push({
          name: "User Profile",
        })
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    open(link) {
      if (!link.title) {
        // window.open(`tel:${link.value}`);
        window.location.href = `tel:${link.value}`;
      } else {
        window.location.href = `mailto:${link.text}?subject=INQUERY&body=Type%20your%20message%20here`;
      }
    },
  },
  // mounted() {
  //   console.log(this.$store.getters.getCurrentUser);
  // },
};
</script>

<style scoped>
* {
  color: #ffffff !important;
}
</style>
