import axios from "axios";
// import getters from '@/store/getters'

var base = `${process.env.VUE_APP_BASE_URL}/subcategory/`;

// baseURL: process.env.VUE_APP_API,
const instance = axios.create({
  baseURL: base,
});

export default instance;
