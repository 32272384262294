<template>
  <v-dialog v-model="dialogLoader" width="100" persistent>
    <!-- <v-card flat class="transparent d-flex justify-center align-center"> -->
    <v-img :src="AssetImage('img', 'SSMJ Assets/ssmjspinner.gif')" width="350"></v-img>
    <!-- <v-progress-linear
        indeterminate
        :color="$store.state.secondaryColor"
        height="5"
      ></v-progress-linear> -->
    <!-- </v-card> -->
  </v-dialog>
</template>

<script>
import utils from '@/use/functions'
export default {
  props: ["dialog"],

  computed: {
    dialogLoader() {
      return this.dialog;
    },
  },
  methods: {
    ...utils
  }
};
</script>

