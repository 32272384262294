import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import scrollAnimation from "./directives/scrollAnimation";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCarousel from "vue-carousel";
import VueGoogleCharts from "vue-google-charts/legacy";
import FlagIcon from "vue-flag-icon";
import "@/plugins/apexcharts";
import vSelect from "vue-select";
import Embed from "v-video-embed";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import i18n from "./plugins/i18n";
import * as VueGoogleMaps from "vue2-google-maps"; // Import map package
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-select/dist/vue-select.css";
import { createHead } from "@unhead/vue";
import { UnheadPlugin } from "@unhead/vue/vue2";
import Ads from "vue-google-adsense";

Vue.directive("scrollanimation", scrollAnimation);
Vue.component(VueCropper);
Vue.component("v-select", vSelect);
Vue.use(FlagIcon);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: "places",
  },
});

Vue.use(VueCarousel);
Vue.use(VueGoogleCharts);
Vue.use(Embed);
Vue.use(require("vue-script2"));
Vue.use(Ads.Adsense);
// Vue.use(VueAos);
Vue.config.productionTip = false;

const head = createHead();
Vue.mixin(UnheadPlugin(head));

router.beforeEach(async (to, from, next) => {
  let lang = to.params.lang;
  if (lang == "" || !i18n.availableLocales.includes(lang)) {
    to.params.lang = lang = i18n.fallbackLocale;

    router.replace({
      name: to.name,
      params: {
        lang: lang,
      },
    });
  }
  i18n.locale = lang;
  next();
});
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
