import { format } from "date-fns";
import datas from "./datas";
import store from "@/store";
import router from "@/router";

export default {
  dateSuffix(d) {
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  },
  dateFormat_Day(date) {
    let d = date != "" ? format(new Date(date), "eee, dd/MM/yy") : date;
    return d;
  },
  dateFormat_Month(date) {
    let d = date != "" ? format(new Date(date), "MMMM dd, yyyy") : date;
    return d;
  },
  dateFormat_short(date) {
    let d = date ? format(new Date(date), "yyyy-MM-dd") : date;
    return d;
  },
  dateFormat_nth(date) {
    let d = date != "" ? new Date(date) : date;
    let day = d.getDate();
    let month = datas.months[d.getMonth()];
    let year = d.getFullYear();
    return day + this.dateSuffix(day) + " " + month.substr(0, 3) + " " + year;
  },
  numberOfDays(date) {
    let date1 = new Date(date);
    let date2 = new Date();
    let timeDifference = date2.getTime() - date1.getTime();
    let h = Math.floor(timeDifference / (1000 * 60 * 60).toFixed(1));
    let hour = h > 1 ? h + " hours" : h + " hour";
    let daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    let res =
      h < 1
        ? "Less than an hour"
        : daysDifference < 1
        ? hour
        : daysDifference > 1
        ? `${daysDifference} days`
        : `${daysDifference} day`;
    return res + " ago";
  },
  breakText(text, limit) {
    // let charLimit = 110;
    if (text.length <= limit) return text;
    else return text.substr(0, limit) + " ... ";
  },
  numberFormat(val) {
    var decimalCount = 2;
    var decimal = ".";
    var thousands = ",";
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = val < 0 ? "-" : "";
    let i = parseInt(
      (val = Math.abs(Number(val) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(val - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  },
  currencyFormat(val) {
    val = val != null ? val : 0;
    return `${store.state.currency.sign + " " + this.numberFormat(val)}`;
  },
  toBase64: (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    }),
  AssetImage(dir, image) {
    if (dir == "") return require("@/assets/" + image);
    return require("@/assets/" + dir + "/" + image);
  },
  randPassword() {
    const letter = 4;
    const number = 4;
    const char = 1;
    const pattern = [
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
      "0123456789",
      "!@#$%^&*",
    ];

    return [letter, number, char]
      .map((len, i) => {
        return Array(len)
          .fill(pattern[i])
          .map((x) => {
            return x[Math.floor(Math.random() * x.length)];
          })
          .join("");
      })
      .concat()
      .join("")
      .split("")
      .sort(() => {
        return 0.5 - Math.random();
      })
      .join("");
  },
  sanitizeTitle(word) {
    const title = word.replaceAll("-", " ");
    const firstLetter = title.charAt(0).toUpperCase();
    return firstLetter + title.slice(1);
  },
  makeUppercase(event) {
    event.target.value = event.target.value.toUpperCase();
  },
  getDates(wkStart, endWeek) {
    let dates = [];
    for (
      let date = wkStart;
      date <= endWeek;
      date.setDate(date.getDate() + 1)
    ) {
      let formattedDate = this.dateFormat_Day(date);
      dates.push(
        navigator.userAgentData.mobile
          ? formattedDate.slice(0, 3)
          : formattedDate
      );
    }
    return dates;
  },
  getLast7Days() {
    let dates = [];
    let start = new Date(new Date().setDate(new Date().getDate() - 6));
    for (
      let date = start;
      date <= new Date();
      date.setDate(date.getDate() + 1)
    ) {
      dates.push(this.dateFormat_short(date));
    }
    return dates;
  },
  saveRedirectRoute(route) {
    store.commit("SET_ACTION_REDIRECT", route);
    router.push({
      name: "Login",
    });
  },
};
export function errorHandler(e) {
  if (typeof e === "object") {
    if (e.response && e.response.data && e.response.data) {
      return e.response.data;
    }
  }
}
