<template>
  <div class="">
    <!-- :style="{ top: $vuetify.application.top + 'px' }" -->
    <v-navigation-drawer
      v-model="drawer"
      app
      width="100%"
      class="primary"
      height="100%"
    >
      <v-list dense class="pa-0" dark>
        <v-list-item class="py-2 d-flex justify-space-between align-center">
          <v-btn fab @click="$emit('close')" color="main" depressed small>
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-card
            flat
            width="50%"
            height="50"
            class="overflow-hidden transparent"
          >
            <v-img
              contain
              :src="AssetImage('', 'Frame2.png')"
              height="100%"
              class=""
            ></v-img>
          </v-card>
        </v-list-item>
        <div class="pa-0 mx-4">
          <v-progress-linear
            indeterminate
            color="secondary"
            :active="$store.getters.getLoader"
            height="6"
          ></v-progress-linear>
        </div>
        <!-- <v-list-item class="py-0">
          <v-text-field
            outlined
            dense
            class="caption"
            placeholder="Search..."
            hide-details
            background-color="rgba(208, 220, 221, 1)"
            light
            type="search"
          ></v-text-field>
        </v-list-item> -->
        <div class="main border-radius mx-4 py-2 my-2 text-capitalize">
          <div v-for="(link, i) in links" :key="i">
            <v-list-item
              link
              dense
              v-if="!link.child"
              @click="scroll(link, false)"
              :active="link.active"
              active-color="main"
              class="py-0"
            >
              <v-icon x-small class="mr-2" color="green lighten-5"
                >mdi-{{ link.icon }}</v-icon
              >
              <v-list-item-title class="caption">
                <span>{{ $tc(link.name) }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-group
              color="primary"
              no-action
              :value="false"
              v-if="link.child"
            >
              <template v-slot:activator>
                <v-list-item link dense class="px-0 py-0">
                  <v-icon x-small class="mr-2" color="green lighten-5"
                    >mdi-{{ link.icon }}</v-icon
                  >
                  <v-list-item-title class="caption">
                    <span>{{ $tc(link.name) }}</span>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-list-item
                link
                dense
                v-for="(item, i) in link.child"
                :key="i"
                target="_blank"
                @click="scroll(item, item.external)"
                class="pl-10"
              >
                <v-icon x-small class="mr-2 grey-lighten-3"
                  >mdi-chevron-right</v-icon
                >
                <v-list-item-title class="caption">
                  <span>{{ $tc(item.name) }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </div>
        <div class="caption font-weight-bold text-uppercase pt-5 mx-5">
          <span>Legal</span>
        </div>
        <v-list-item
          class="main mt-1 caption mx-4 border-radius"
          @click="goTo()"
          dense
        >
          <v-icon small class="mr-2">mdi-chevron-right</v-icon>
          <span>Terms & conditions</span>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div
          class="py-1 d-flex flex-column justify-center caption white--text mx-4"
        >
          <span class="mx-auto"
            >&copy; {{ new Date().getFullYear() }} South Sudan Mining
            Journal</span
          >
          <div
            class="d-flex justify-space-between align-center px-3 main border-radius my-1 py-2"
          >
            <social :large="true" />
            <v-spacer></v-spacer>
            <v-btn
              depressed
              dark
              class="primary caption text-capitalize white--text font-weight-bold"
              @click="loginLink()"
            >
              <span>{{ isLoggedIn ? "logout" : "login" }}</span>
            </v-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Social from "../models/social.vue";
import functions from "@/use/functions";
import { mapGetters } from "vuex";

export default {
  components: { Social },
  props: {
    drawer: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      // links: [
      //   {
      //     name: this.$t("news & articles"),
      //     id: "news",
      //     link: "newsView",
      //     active: false,
      //     icon: "newspaper-variant",
      //   },
      //   {
      //     name: this.$t("researches & journals"),
      //     id: "analysis",
      //     link: "Researches & Journals",
      //     icon: "note-search",
      //     active: false,
      //   },
      //   {
      //     name: this.$t("reports & maps"),
      //     id: null,
      //     // description: "Coming Soon",
      //     link: "Reports and Maps",
      //     icon: "map",
      //     active: false,
      //   },
      //   {
      //     name: this.$t("cadastre portals"),
      //     query: "mining-portals",
      //     id: "geologicalData",
      //     link: "Mining Cadastre Portals",
      //     icon: "link-variant",
      //     description: "",
      //     external: false,
      //   },
      //   {
      //     name: "SSMJ",
      //     id: "articles",
      //     active: false,
      //     icon: "clipboard-text-search",
      //     children: [
      //       {
      //         name: this.$t("about us"),
      //         id: "about-us",
      //         link: "About Us",
      //         active: false,
      //         submenu: false,
      //         img: "information",
      //       },
      //       {
      //         name: this.$t("editorial board"),
      //         id: "editorialTeam",
      //         link: "Editorial Team", //
      //         active: false,
      //         submenu: false,
      //         img: "account-multiple",
      //       },
      //       {
      //         name: this.$tc("policy", 1),
      //         id: "null",
      //         link: "Privacy", //
      //         active: false,
      //         submenu: false,
      //         img: "key-alert",
      //       },
      //       {
      //         name: this.$t("adminPortal.drawer.investor guide"),
      //         id: "null",
      //         link: "Investor Guide Doc", //
      //         active: false,
      //         submenu: false,
      //         img: "handshake",
      //       },
      //     ],
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      profileLinks: "webProfileLinks",
      mainLinks: "webMainLinks",
    }),
    links() {
      return this.$route.fullPath.includes("/profile/")
        ? this.profileLinks
        : this.mainLinks;
    },
    isLoggedIn() {
      return this.currentUser?.fullName ? true : false;
    },
  },
  watch: {
    drawer(val) {
      val == false ? this.$emit("close") : "";
    },
    $route() {
      this.checkRoute();
    },
  },

  methods: {
    ...functions,
    scroll(item, external) {
      if (item.link && !external) {
        this.$router.push({ name: item.link }).then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      } else if (item.link && external) {
        window.open(item.link, "_blank");
      } else {
        if (this.$route.name == "Landing") {
          document.getElementById(item.id).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
        } else {
          this.$router.push({ name: "Landing" }).then(() => {
            document.getElementById(item.id).scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "center",
            });
          });
        }
        // this.checkRoute();
      }
    },
    checkRoute() {
      this.links.forEach((link) => {
        if (link.link !== null) {
          link.link == this.$route.name
            ? (link.active = true)
            : (link.active = false);
        }
      });
    },
    goTo() {
      this.$router.push({
        path: "reports-and-maps",
      });
    },
    async loginLink() {
      if (this.isLoggedIn) {
        await this.$store.dispatch("LOGOUT");
      } else this.$router.push({ name: "Login" });
    },
  },
  created() {
    this.checkRoute();
  },
};
</script>

<style scoped>
.v-icon.v-icon {
  font-size: 20px !important;
}
</style>
