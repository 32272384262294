<template>
  <div>
    <app-bar id="appBar" />
    <v-main style="background-color: #f6f6f6">
      <v-layout row wrap class="ma-auto pb-5" justify-center>
        <v-flex class="pa-0" xs12 sm12 md12 lg12 xl8>
          <v-container>
            <router-view></router-view>
          </v-container>
        </v-flex>
      </v-layout>
    </v-main>
    <Footer />

    <v-fab-transition>
      <v-btn
        class="mb-8 mr-3 transition-fast-in-fast-out"
        fab
        right
        bottom
        color="primary"
        elevation="21"
        fixed
        @click="goTo()"
        v-scroll="scrollTo"
        v-show="showGoToTop"
        small
      >
        <v-icon> mdi-chevron-up </v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- <Notifications :showGoToTop="showGoToTop" /> -->
  </div>
</template>

<script>
import Footer from "./models/footer.vue";
import AppBar from "./models/appBar.vue";
import Notifications from "@/views/profile/notifications.vue";
export default {
  name: "HomeView",

  components: { AppBar, Footer, Notifications },
  data() {
    return {
      offsetTop: 0,
    };
  },
  created() {
    window.onscroll = () => {
      this.setAppbar();
    };
  },
  computed: {
    showGoToTop() {
      return this.offsetTop > 800;
    },
  },

  methods: {
    setAppbar() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.$store.commit("setTopAppBar", false);
      } else {
        this.$store.commit("setTopAppBar", true);
      }
    },
    scrollTo(event) {
      this.offsetTop = event.target.scrollingElement.scrollTop;
    },
    goTo() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="css" scoped>
.notifications-button {
  --duration: 1s;
  --method: ease-in-out;
  transition: all var(--duration) var(--method) !important;
}
.increase-margin-bottom {
  transition: all var(--duration) var(--method) !important;
  bottom: 15vh !important;
}
</style>
