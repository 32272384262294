import axios from "@/plugins/axios/policyApi";

export default {
  GET_POLICIES: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("allPolicies", config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.policies);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  SINGLE_POLICY: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`singlePolicy/${payload}`, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.policy);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },

  CREATE_POLICY: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.post("createPolicy", payload, config);
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  UPDATE_POLICY: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.put(
        `updatePolicy/${payload.id}`,
        payload,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
      console.log(response);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  DELETE_POLICY: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.delete(`deletePolicy/${payload.id}`, config);
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
      console.log(response);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
};
