import axios from '@/plugins/axios/roleApi'

export default {
  GET_ROLES: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    // commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('allRoles', config)
        .then(res => {
          resolve(res.data.roles)
        })
        .catch(error => {
          commit('SET_ERROR', true)
          reject(error.response?.data.message || error.message)
        })
    })
  }
}
