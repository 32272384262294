import axios from "@/plugins/axios/commodityPricingApi";

export default {
  GET_PRODUCT_PRICINGS: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("allProductPricings", config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.productPricings);
          // console.log(res)
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_LOADER", false);
          reject(err);
        });
    });
  },
  CREATE_PRODUCT_PRICING: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.post("addProductPricing", payload, config);
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
      // console.log(response)
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  UPDATE_PRODUCT_PRICING: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.put(
        `updateProductPricing/${payload.id}`,
        payload,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
      console.log(response);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  DELETE_PRODUCT_PRICING: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.delete(
        `deleteProductPricing/${payload.id}`,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
      // console.log(response)
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  SINGLE_PRODUCT_PRICING: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("pricingPerProduct", payload, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.productPricings);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_LOADER", false);
          reject(err);
        });
    });
  },
  MONTHLY_PRODUCT_PRICING: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`monthlyProductPricing/${payload.month}`, payload.name, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.productPricing);
          console.log(res);
        })
        .catch((err) => {
          commit("SET_ERROR", true);
          commit("SET_LOADER", false);
          reject(err);
        });
    });
  },
};
