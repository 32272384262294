export default {
    light: {
        primary: "#136C7E",
        main: "#317E8E",
        secondary: "#00CAF3",
        white: "#FFFFFF",
        black: "#000000",
        titleColor: '#48A2B5',
        mainAdmin: "#f6f8fa",
        secondaryAdmin: "#24292f",
        tertiary: '#00CAF3',
        appBarColor: "#24292f",
        border: "#30363d",
        lightColor: '#c9d1d9',
        darkColor: '#24292f',
    },
    dark: {},
};