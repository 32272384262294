import state from "./state";

export default {
  //*******GENERAL******** */

  UNDER_MAINTENANCE(state, payload) {
    state.system_under_maintenance = payload;
  },
  SET_APP(state, payload) {
    state.app = payload;
  },

  SET_LOADER(state, payload) {
    state.loader = payload;
  },

  SET_PROGRESS(state, payload) {
    state.progress = payload;
  },

  SNACKBAR(state, payload) {
    state.snackbar = payload;
  },

  TOGGLE_NAVIGATION_DRAWER(state, payload) {
    state.drawer = payload;
  },

  /*****SETS***8 */
  SET_SESSION(state, payload) {
    state.currentUser.response_data = payload;
  },
  SET_CURRENCY(state, payload) {
    state.currency = payload;
  },

  SET_ACCESS_TOKEN(state, payload) {
    state.currentUser.access_token = payload;
  },
  SET_PAGES(state, payload) {
    state.pages = payload;
  },

  /************END********** */

  SET_MENU(state, payload) {
    state.menuList = payload;
  },

  setTopAppBar(state, payload) {
    state.topAppBar = payload;
  },
  SET_MOBILE_DRAWER(state, payload) {
    state.mobileDrawer = payload;
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_JOB_TYPES(state, payload) {
    state.jobTypes = payload;
  },
  SET_JOBS(state, payload) {
    state.jobs = payload;
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  SET_EVENT_TYPES(state, payload) {
    state.eventTypes = payload;
  },
  SET_EVENTS(state, payload) {
    state.events = payload;
  },
  SET_NEWS_TYPES(state, payload) {
    state.newsTypes = payload;
  },
  SET_NEWS(state, payload) {
    state.news = payload;
  },
  SET_RESEARCHES(state, payload) {
    state.researches = payload;
  },
  SET_SINGLE_NEWS(state, payload) {
    state.singleNews = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_SUBCATEGORIES(state, payload) {
    state.subCategories = payload;
  },
  SET_SOURCES(state, payload) {
    state.sources = payload;
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_COUNTRIES(state, payload) {
    state.countries = payload;
  },
  SET_TOP_NEWS(state, payload) {
    state.scraped.topNews = payload;
  },
  SET_SUDAN_TRIBUNE(state, payload) {
    state.scraped.sudanTribune = payload;
  },
  SET_MINING_TECHNOLOGY(state, payload) {
    state.scraped.miningTechnology = payload;
  },
  SET_RADIO_TAMAZUJ(state, payload) {
    state.scraped.radioTamazuj = payload;
  },
  SET_ALL_NEWS(state, payload) {
    state.allNews = payload;
  },
  SET_COMMENT(state, payload) {
    state.comment = payload;
  },
  SET_COMMODITIES(state, payload) {
    state.commodities = payload;
  },
  SET_ACTION_REDIRECT(state, payload) {
    state.redirect = payload;
  },
};
