import axios from '@/plugins/axios/videoApi'

export default {
    GET_VIDEOS: async ({ commit, getters }) => {
        commit('SET_ERROR', false)
        commit('SET_LOADER', true)
        var config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getters.getToken
            }
        }
        return new Promise((resolve, reject) => {
            axios
                .get('allVideos', config)
                .then(response => {
                    commit('SET_LOADER', false)
                    resolve(response.data.videos)
                    console.log(res)
                })
                .catch(error => {
                    reject(error)
                    commit('SET_LOADER', false)
                    commit('SET_ERROR', true)
                })
        })
    },
    CREATE_VIDEO: async ({ commit, getters }, payload) => {
        commit('SET_ERROR', false)
        commit('SET_LOADER', true)
        var config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getters.getToken
            }
        }
        try {
            let response = await axios.post('addVideo', payload, config)
            commit('SET_LOADER', false)
            commit('SNACKBAR', {
                snackbar: true,
                message: response.data.message,
                type: 'success'
            })
        } catch (error) {
            commit('SET_LOADER', false)
            commit('SET_ERROR', true)
            commit('SNACKBAR', {
                snackbar: true,
                message: error.response.data.message,
                type: 'error'
            })
            console.log(error)
        }
    },
    UPDATE_VIDEO: async ({ commit, getters }, payload) => {
        commit('SET_ERROR', false)
        commit('SET_LOADER', true)
        var config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getters.getToken
            }
        }
        try {
            let response = await axios.put(
                `updateVideo/${payload.id}`,
                payload,
                config
            )
            commit('SET_LOADER', false)
            commit('SNACKBAR', {
                snackbar: true,
                message: response.data.message,
                type: 'success'
            })
            console.log(response)
        } catch (error) {
            commit('SET_LOADER', false)
            commit('SET_ERROR', true)
            commit('SNACKBAR', {
                snackbar: true,
                message: error.response.data.message,
                type: 'error'
            })
            console.log(error)
        }
    },
    DELETE_VIDEO: async ({ commit, getters }, payload) => {
        commit('SET_ERROR', false)
        commit('SET_LOADER', true)
        var config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getters.getToken
            }
        }
        try {
            let response = await axios.delete(`deleteVideo/${payload.id}`, config)
            commit('SET_LOADER', false)
            commit('SNACKBAR', {
                snackbar: true,
                message: response.data.message,
                type: 'success'
            })
            console.log(response)
        } catch (error) {
            commit('SET_LOADER', false)
            commit('SET_ERROR', true)
            commit('SNACKBAR', {
                snackbar: true,
                message: error.response.data.message,
                type: 'error'
            })
            console.log(error)
        }
    }
}
