import axios from "@/plugins/axios/newsApi";
import i18n from "@/plugins/i18n";

const clientPortal = "client-portal";

export default {
  GET_NEWS: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
        lang: i18n.locale,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`allPosts`, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.posts);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  GET_PUBLISHED_NEWS: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
        lang: i18n.locale,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`publishedPosts`, config)
        .then((res) => {
          commit("SET_LOADER", false);
          // console.log(res);
          resolve(res.data.posts);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  GET_PUBLISHED_ARTICLES: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("publishedPosts", config)
        .then((res) => {
          commit("SET_LOADER", false);
          let articles = res.data.posts.filter((val) => val.typeId == 4);
          resolve(articles);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  GET_SINGLE_NEWS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Expose-Headers": ["Set-Cookie"],
        Authorization: "Bearer " + getters.getToken,
        client: payload.client ?? false,
        user: getters.getCurrentUser?.userId ?? "",
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`singlePost/${payload.post}`, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.post);
        })
        .catch((error) => {
          reject(error);
          commit("SNACKBAR", {
            snackbar: true,
            message: error.response.data.message ?? error.message,
            type: "error",
          });
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  GET_MOST_VIEWED: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
        lang: i18n.locale,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`mostViewedPosts`, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.posts);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  CREATE_NEWS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.post("createPost", payload, config);
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response?.data.message || error.message,
        type: "error",
      });
      console.log(error);
    }
  },
  UPDATE_NEWS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
        lang: payload.language,
      },
    };
    try {
      let response = await axios.put(
        `updatePost/${payload.post.id}`,
        payload.post,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  AMEND_NEWS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
        lang: payload.language,
      },
    };
    try {
      let response = await axios.put(
        `amendPost/${payload.post.id}`,
        payload.post,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  UPDATE_NEWS_VIEWS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.patch(
        `updatePostViews/${payload.id}`,
        payload,
        config
      );
      commit("SET_LOADER", false);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
    }
  },
  UPDATE_NEWS_STATUS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.patch(
        `modifyPost/${payload.id}`,
        payload,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
    }
  },
  DELETE_NEWS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.delete(`deletePost/${payload.id}`, config);
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
      console.log(response);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },

  LIKED_NEWS: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${clientPortal}/likedNews`, config)
        .then((response) => {
          commit("SET_LOADER", false);
          resolve(response.data.likedPosts);
          // console.log(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  LIKE_NEWS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.post(
        `${clientPortal}/likeNews`,
        payload,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
      // console.log(response)
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  UNLIKE_NEWS: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.delete(
        `${clientPortal}/removeNews/${payload}`,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
    }
  },
};
