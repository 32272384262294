import axios from "axios";
// import getters from '@/store/getters'

var base = "https://ssmj-research-api.vercel.app/api/v1/researchPaper/";

// baseURL: process.env.VUE_APP_API,
const instance = axios.create({
  baseURL: base,
});

export default instance;
