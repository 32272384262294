import axios from '@/plugins/axios/newsTypeApi'

export default {
  GET_NEWS_TYPES: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('allPostTypes', config)
        .then(res => {
          commit('SET_LOADER', false)
          resolve(res.data.postTypes)
        })
        .catch(err => {
          reject(err)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })
  },

  CREATE_NEWS_TYPE: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.post('createPostType', payload, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  UPDATE_NEWS_TYPE: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.put(
        `updatePostType/${payload.id}`,
        payload,
        config
      )
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  DELETE_NEWS_TYPE: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.delete(`deletePostType/${payload.id}`, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  }
}
