<template>
  <v-app>
    <v-fade-transition>
      <router-view />
    </v-fade-transition>

    <snackbar-alert :payload="snackbarPayload" @snackbar="closeSnackbar()" />
  </v-app>
</template>

<script>
import SnackbarAlert from "@/components/models/SnackbarAlert";
import DialogDataLoader from "./components/models/DialogDataLoader.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "App",

  head() {
    return {
      title: "SSMJ",
      titleTemplate:
        "%s - South Sudan Mining Journal, Mining news and analysis",
      meta: [
        {
          name: "description",
          content:
            "A reliable source of information and analysis for investors, policymakers, researchers and industry professionals.",
        },
        {
          name: "keywords",
          content:
            "South Sudan, Mining, Journal, news, insights, industry, sector, development, trend, challenge, article, interview, report, analysis, expert, stakeholder, south sudan mining human rights violations, mine south sudan mining company, south sudan mining jobs, south sudan news, juba, juba south sudan",
        },
        {
          property: "og:image",
          content: "https://www.southsudanminingjournal.co/og_logo1.png",
        },
        {
          property: "og:url",
          content: () => encodeURI(window.location.href),
        },
        {
          property: "og:title",
          content:
            "South Sudan Mining Journal - News and Analysis on Mining in South Sudan",
        },
        {
          property: "og:description",
          content:
            "A reliable source of information and analysis for investors, policymakers, researchers and industry professionals.",
        },
        {
          name: "twitter:url",
          content: () => encodeURI(window.location.href),
        },
        {
          name: "twitter:title",
          content: () =>
            "South Sudan Mining Journal - News and Analysis on Mining in South Sudan",
        },
        {
          name: "twitter:description",
          content:
            "A reliable source of information and analysis for investors, policymakers, researchers and industry professionals.",
        },
        {
          name: "twitter:image",
          content: "https://www.southsudanminingjournal.co/og_logo1.png",
        },
      ],
    };
  },
  components: {
    SnackbarAlert,
    DialogDataLoader,
  },

  data: function () {
    return {
      dialogSession: false,
      dialogLogout: false,
      dialog: false,
    };
  },

  mounted() {
    this.checkAccessibility();
  },
  computed: {
    ...mapGetters({
      dialogLoader: "getLoader",
      snackbarPayload: "getSnackbar",
      user: "getCurrentUser",
      textDirection: "getTextDirection",
    }),
  },

  watch: {
    dialogLogout() {
      if (!this.dialogLogout) {
        this.logout();
      }
    },
    // user(val) {
    //   if (typeof val === null) {
    //     console.log(val, this.$route.name);
    //     window.location.reload();
    //   }
    // },

    $route() {
      this.checkAccessibility();
      this.setDocumentTextDirection();
    },
  },

  methods: {
    checkAccessibility() {
      // this.$store.dispatch("system_app_state");
    },
    checkActivity() {
      this.events.forEach((event) => {
        window.addEventListener(event, this.resetTimer);
      });

      this.setTimers();
    },

    setTimers() {
      // alert(this.$route.name)
      if (this.$route.name != "Login") {
        this.warningTimer = setTimeout(this.warningMessage, 14 * 60 * 1000); //14 minutes  14*60*1000

        this.logoutTimer = setTimeout(this.expiredSession, 15 * 60 * 1000); // 15 minutes 15*60*1000
      }
    },

    warningMessage() {
      this.$store.commit("SNACKBAR", {
        snackbar: true,
        message: "Are you still using the system ?",
        type: "warning",
      });
    },

    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    closeDialog() {
      this.$store.commit("LOADER", false);
    },

    closeSnackbar() {
      this.$store.commit("SNACKBAR", {
        snackbar: false,
        message: "",
        type: "info",
      });
    },

    expiredSession() {
      this.logout();
    },

    async logout() {
      // await this.$store.dispatch("LOGOUT");
      this.$router.push({ name: "Login" });
      console.log("logged out");
    },
    async getCountries() {
      axios.get("/data/countries.json").then((response) => {
        this.$store.commit("SET_COUNTRIES", response.data);
      });
    },
    setDocumentTextDirection() {
      let doc = document.getElementsByTagName("html")[0];
      let direction = this.$i18n.locale == "ar" ? "rtl" : "ltr";
      doc.setAttribute("dir", direction);
    },
  },
  created() {
    this.getCountries();
    this.setDocumentTextDirection();
  },
};
</script>

<style src="./assets/css/app.css"></style>
