<template>
  <div class="">
    <menus
      :drawer="drawer"
      @close="drawer = false"
      v-if="$vuetify.breakpoint.smAndDown"
    />

    <v-app-bar
      app
      fixed
      hide-on-scroll
      elevate-on-scroll
      dark
      prominent
      class="pa-0 white"
      short
      elevation="5"
    >
      <v-layout column class="ma-auto mt-0 pa-0">
        <upperAppBar />
        <LowerAppBar @open="drawer = true" class="py-0" />
      </v-layout>
      <v-progress-linear
        indeterminate
        color="secondary"
        :active="$store.getters.getLoader"
        absolute
      ></v-progress-linear>
    </v-app-bar>
  </div>
</template>

<script>
import LowerAppBar from "../app/lowerAppBar.vue";
import upperAppBar from "../app/upperAppBar.vue";
import Menus from "../app/menus.vue";
export default {
  components: { upperAppBar, LowerAppBar, Menus },
  name: "AppBar",
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style scoped>
.v-app-bar >>> .v-toolbar__content {
  padding: 0px !important;
}
</style>
