import axios from '@/plugins/axios/commentApi'

export default {
  GET_COMMENTS: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get('allComments', config)
        .then(response => {
          commit('SET_LOADER', false)
          resolve(response.data.comments)
        })
        .catch(error => {
          reject(error)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })
  },
  GET_COMMENT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`singleComment/${payload}`, config)
        .then(response => {
          commit('SET_LOADER', false)
          resolve(response.data.comment)
          console.log(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })
  },
  CREATE_COMMENT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.post('createComment', payload, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  UPDATE_COMMENT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.put(
        `updateComment/${payload.id}`,
        payload,
        config
      )
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  DELETE_COMMENT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.delete(`deleteComment/${payload.id}`, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  POST_COMMENTS: async ({ commit, getters }, postId) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`allComments`, config)
        // .get(`singlePostComments/${postId}`, config)
        .then(res => {
          commit('SET_LOADER', false)
          let comments = res.data.comments.filter((comment) => comment.postId == postId)
          resolve(comments)
          // resolve(res.data.postComments)
          // console.log(res)
        })
        .catch(err => {
          reject(err)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })
  },
}
