export default {
  getJobType(types, id) {
    // return (
    //   types.find(jobType => {
    //     return jobType.id == id
    //   }).jobType || 'N/A'
    // )
    let type = types.find((jobType) => jobType.id == id);
    return type ? type.jobType : "Job type";
  },
  getEventType(type, id) {
    let event = type.find((eventType) => eventType.id == id);
    return event ? event.eventType : "Event type";
  },
  getNewsType(types, id) {
    let newsType = types.find((newsType) => newsType.id == id);
    return newsType ? newsType.postType : "News type";
  },
  getCategory(categories, id) {
    var data = categories.find((item) => item.id == id);
    return data?.category;
  },
  getSubcategory(categories, id) {
    var data = categories.find((item) => item.id == id);
    return data?.subcategory;
  },
  getRole(roles, accessLevel) {
    var role = roles.find((i) => i.accessLevel == accessLevel);
    return role?.roleName || "Role";
  },
  getUser(id) {
    return (
      this.$store.getters.getUsers.find((user) => {
        return user.userId == id;
      }).fullName || "User names"
    );
  },

  getStringedDescription(description) {
    var desc = JSON.parse(description);
    var str = "";

    desc.forEach((element) => {
      if (typeof element["insert"] == "string") {
        str += element.insert;
      }
    });
    return str;
  },

  getDomDescription(news) {
    var str = "";
    if (!news.scraped) {
      let desc = JSON.parse(news.description);

      desc.forEach((element) => {
        if (typeof element["insert"] == "string") {
          var reg = /\\n/g;
          var stringify = JSON.stringify(element.insert).replace(reg, "</br>");
          var styles = "";
          if (element.attributes) {
            for (var style in element.attributes) {
              styles += `${style}:${element.attributes[style]};`;
            }
          }
          str += `<p style='${styles}'>
          ${JSON.parse(stringify)}
        </p> `;
        }
        if (typeof element.insert == "object") {
          for (var i in element.insert) {
            if (i == "image") {
              str += `<img class="post-image" src="${element.insert[i]}"/>`;
            }
          }
        }
      });
    } else {
      str = news.description; //`<p>${news.description}</p>`
    }
    return str;
  },
  formatQuillText(text) {
    var str = "";
    let desc = JSON.parse(text);
    desc.forEach((element) => {
      if (typeof element["insert"] == "string") {
        var reg = /\\n/g;
        var stringify = JSON.stringify(element.insert);
        var styles = "";
        if (element.attributes) {
          for (var style in element.attributes) {
            // if (style == 'italic' && element.attributes[style]) {
            //   style = 'font-style'
            //   element.attributes[style] = 'italic'
            // }
            styles += `${style}:${element.attributes[style]} !important;`;
          }
        }
        str += `<p style='${styles}'>
          ${JSON.parse(stringify)}
        </p> `;
      }
      if (typeof element.insert == "object") {
        for (var i in element.insert) {
          if (i == "image") {
            str += `<img class="post-image" src="${element.insert[i]}"/>`;
          }
        }
      }
    });
    return str;
  },
  spaceReducer(text) {
    return text.replaceAll("&lt;", "<").replaceAll("<br>", " ");
  },
  shortDesc(text) {
    return text
      .replaceAll("&lt;", "<")
      .replaceAll("strong", "span")
      .replaceAll("<p", "<span")
      .replaceAll("</p>", "</span>");
  },
  sanitizeMetaDescription(description) {
    let html = this.shortDesc(description || "");
    let div = document.createElement("div");
    div.innerHTML = html;
    let text = div.textContent || div.innerText || "";
    return `${text.substring(0, 114)}...`;
  },
  getLocaleName(locale) {
    return this.$store.state.languages.find((l) => l.lang == locale).name;
  },
  getItemTranslation(item, lang) {
    let locale = lang ?? this.$i18n.locale;
    for (let i in item) {
      return i == locale ? item[i] : item[locale];
    }
  },
  language(action, locale) {
    return action == "create"
      ? ""
      : String(this.getLocaleName(locale)).toUpperCase();
  },
  getReview(postId, reviews) {
    let postReviews = reviews?.find((review) => review.postId == postId);
    return postReviews;
  },
  getPostComment(postId, comments) {
    let postComment = comments?.filter((comment) => comment.postId == postId);
    return postComment;
  },
};
