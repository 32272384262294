<template>
  <div class="d-flex justify-center align-center">
    <v-card
      :width="200"
      height="100%"
      flat
      class="transparent overflow-hidden px-3"
    >
      <v-img
        contain
        src="/Frame.svg"
        lazy-src="/Frame.svg"
        @click="$emit('scroll')"
        style="cursor: pointer"
        height="100%"
        :class="{ 'mx-auto': isLoggedIn }"
      ></v-img>
    </v-card>
  </div>
  <!-- <img
      src="/Frame.png"
      class="mx-auto"
      height="38"
      alt=""
      @click="$emit('scroll')"
      style="cursor: pointer"
    /> -->
</template>

<script>
import functions from "@/use/functions";
import { mapGetters } from "vuex";
export default {
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.getters.getMobileDrawer;
      },
      set(val) {
        return val;
      },
    },
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),

    isLoggedIn() {
      return this.currentUser?.fullName ? true : false;
    },
  },
  methods: {
    ...functions,
    openDrawer() {
      this.$store.commit(
        "SET_MOBILE_DRAWER",
        !this.$store.getters.getMobileDrawer
      );
      console.log(this.$store.getters.getMobileDrawer);
    },
  },
};
</script>

<style></style>
