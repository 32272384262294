import { render, staticRenderFns } from "./notifications.vue?vue&type=template&id=4a3f14e1&scoped=true&"
import script from "./notifications.vue?vue&type=script&lang=js&"
export * from "./notifications.vue?vue&type=script&lang=js&"
import style0 from "./notifications.vue?vue&type=style&index=0&id=4a3f14e1&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a3f14e1",
  null
  
)

export default component.exports