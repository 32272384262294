import axios from '@/plugins/axios/eventTypeApi'

export default {
  GET_EVENT_TYPES: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    // commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get('allEventTypes', config)
        .then(response => {
          commit('SET_LOADER', false)
          resolve(response.data.eventTypes)
          // console.log(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })
  },
  CREATE_EVENT_TYPE: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.post('createEventType', payload, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  UPDATE_EVENT_TYPE: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.put(
        `updateEventType/${payload.id}`,
        payload,
        config
      )
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  DELETE_EVENT_TYPE: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.delete(`deleteEventType/${payload.id}`, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  }
}
