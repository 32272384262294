var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{staticClass:"pa-0",attrs:{"dark":"","height":"auto"}},[_c('div',{staticClass:"appBarColor",staticStyle:{"width":"100%"}},[_c('marquee',{staticClass:"caption appBarColor py-2",staticStyle:{"width":"100%"},attrs:{"behavior":"","direction":_vm.$i18n.locale == 'ar' ? 'right' : 'left',"scrolldelay":"200","onmouseover":"this.stop()","onmouseout":"this.start()"}},[(_vm.commodities.length)?_c('div',{},_vm._l((_vm.commodities),function(commodity){return _c('span',{staticClass:"mx-4"},[_c('strong',[_vm._v(_vm._s(commodity.product))]),_c('span',{staticClass:"mx-2"},[_c('span',[_vm._v(_vm._s(_vm.currencyFormat(commodity.price) + " / " + commodity.unit))]),_c('v-icon',{staticClass:"mb-1",attrs:{"small":"","right":"","color":_vm.getSign(commodity) ? 'success darken-1' : 'error darken-2'}},[_vm._v(_vm._s(_vm.getSign(commodity) ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"))]),_c('span',[_vm._v(" "+_vm._s(_vm.getDifference(commodity)))])],1)])}),0):_c('div',{},[_c('span',[_vm._v(_vm._s(_vm.$t("products update")))])])])],1),_c('div',[(_vm.$route.name == 'Landing')?_c('GoogleMap'):_vm._e(),_c('FooterContent'),_c('v-layout',{staticClass:"ma-auto primary py-3",attrs:{"row":"","wrap":"","justify-space-between":"","align-center":""}},[_c('v-flex',{staticClass:"caption d-flex px-3",class:`${
          _vm.$vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-start'
        }`,attrs:{"xs12":"","sm12":"","md4":"","lg4":"","xl4":""}},[_c('span',{staticClass:"px-0"},[_vm._v("© "+_vm._s(new Date().getFullYear())+" - "+_vm._s(_vm.$t("copyright")))])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":"","lg8":"","xl8":""}},[_c('div',{staticClass:"caption d-flex",class:`${
            _vm.$vuetify.breakpoint.smAndDown
              ? 'justify-center pt-2'
              : 'justify-end px-3'
          }`},_vm._l((_vm.policies.slice(0, 3)),function(policy,i){return _c('a',{key:i,staticClass:"white--text d-flex text-center",staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.redirect(policy.id)}}},[_vm._v(_vm._s(policy.title)+" "),(i < 2)?_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}):_vm._e()],1)}),0)])],1),_c('v-divider',{}),_c('v-layout',{staticClass:"ma-auto py-3 pt-2 primary",attrs:{"row":"","wrap":"","justify-space-between":"","align-center":""}},[_c('v-flex',{class:`px-3 ${
          _vm.$vuetify.breakpoint.smAndDown
            ? 'text-center'
            : _vm.$i18n.locale == 'ar'
            ? 'text-right'
            : 'text-left'
        }`,attrs:{"xs12":"","sm12":"","md6":"","lg6":"","xl6":""}},[_c('div',{staticClass:"caption"},[_c('span',[_vm._v(_vm._s(_vm.$t("powered by")))]),_c('a',{staticClass:"orange--text",attrs:{"href":"https://lilicoengineering.com/","target":"_blank"}},[_vm._v(" Lilico ")])])]),_c('v-flex',{class:`px-3 ${
          _vm.$vuetify.breakpoint.smAndDown
            ? 'text-center'
            : _vm.$i18n.locale == 'ar'
            ? 'text-left'
            : 'text-right'
        }`,attrs:{"xs12":"","sm12":"","md6":"","lg6":"","xl6":""}},[_c('div',{staticClass:"caption"},[_c('span',[_vm._v(_vm._s(_vm.$t("designed and developed by")))]),_c('a',{staticClass:"orange--text",attrs:{"href":"https://mijinitech.com/index.php/en/","target":"_blank"}},[_vm._v(" MijiniTech ")])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }