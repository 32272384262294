<template>
  <div class="footer" :class="{ 'my-5': $route.name !== 'Landing' }">
    <v-layout column class="my-auto" fill-height style="position: relative">
      <ContactCard v-if="$route.name == 'Landing'" />
      <v-layout
        column
        fill-height
        :class="{ margin: $route.name == 'Landing' }"
      >
        <div class="px-8"></div>
      </v-layout>
      <FooterDescription />
    </v-layout>
  </div>
</template>

<script>
import ContactCard from "./contactCard.vue";
import FooterDescription from "./footerDescription.vue";
export default {
  name: "FooterContent",

  components: { ContactCard, FooterDescription },
};
</script>
<style scoped>
.margin {
  margin-top: 240px;
}
</style>
