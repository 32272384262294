<template>
  <v-card width="100vw" height="100vh" tile flat class="d-flex flex-column">
    <v-card
      width="100%"
      tile
      flat
      class="d-flex justify-center align-center flex-column"
      height="95%"
    >
      <div class="">
        <v-img src="/Frame.svg" lazy-src="/Frame.svg" alt="logo"></v-img>
      </div>
      <div
        class="text-center"
        :class="$vuetify.breakpoint.mobile ? 'mobile' : 'computer'"
      >
        <div
          class="font-weight-black my-8"
          :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h3'"
        >
          <b>We've got something special prepared for you</b>
        </div>
        <div class="subtitle-2 my-4 text--disabled">
          <b
            >The site is currently being upgraded, we apologize for any
            inconveniences caused.</b
          >
          <br />
          <b>We are almost done</b>
        </div>
      </div>
    </v-card>
    <v-card class="mt-auto" width="100%" tile flat dark>
      <UpperAppBar />
    </v-card>
    <!-- <social :dark="false" /> -->
  </v-card>
</template>

<script>
import UpperAppBar from "./app/upperAppBar.vue";
export default {
  components: { UpperAppBar },
  head() {
    return {
      title: "System Maintenance",
    };
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  width: 96%;
}
.computer {
  width: 50%;
}
.text-h3 {
  line-height: 54px;
}
.text-h4 {
  font-size: 27px !important;
}
</style>
