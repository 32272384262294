<template>
  <div class="">
    <v-layout
      row
      wrap
      class="px-2 py-4 my-auto"
      align-center
      justify-space-between
    >
      <v-flex xs3 sm3 md0 lg0 xl0 class="d-sm-flex d-md-none text-center">
        <v-app-bar-nav-icon
          @click.stop="$emit('open')"
          v-if="$vuetify.breakpoint.smAndDown"
          color="black"
        ></v-app-bar-nav-icon>
      </v-flex>
      <v-flex sm6 xs6 lg2 md2 xl2 class="pa-0 ma-0">
        <left-lower-menu
          :links="links"
          @open="$emit('open')"
          @scroll="scroll({ id: '' })"
        ></left-lower-menu>
      </v-flex>
      <v-flex
        xs3
        sm3
        lg10
        md10
        xl10
        class="d-flex align-center justify-end pl-2"
        :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
        :dir="$store.getters.getTextDirection"
      >
        <div
          class="d-none d-md-flex d-lg-flex d-xl-flex align-center"
          v-if="!enlarge"
        >
          <a
            v-for="(link, i) in links"
            :key="i"
            :class="`${
              link.active ? 'main--text' : 'black--text'
            }  caption mx-4  font-weight-bold text-capitalize`"
            style="text-decoration: none !important"
            @click="scroll(link, false)"
          >
            <v-hover v-slot="{ hover }">
              <div :class="`${hover ? 'main--text font-weight-bold' : ''}`">
                <v-menu
                  offset-y
                  close-on-click
                  transition="slide-x-reverse-transition"
                  v-if="link.submenu"
                  max-width="300px"
                >
                  <!-- nudge-bottom="10" -->
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="py-7"
                      >{{ $tc(link.name) }}
                      <v-icon right x-small>mdi-chevron-down</v-icon></span
                    >
                  </template>
                  <v-card flat class="menu-list">
                    <v-list dense class="pa-0 transparent">
                      <div v-for="(child, i) in link.child" :key="i">
                        <v-list-item
                          link
                          target="_blank"
                          @click="scroll(child, child.external)"
                        >
                          <v-list-item-avatar
                            rounded
                            size="48"
                            color="rgba(15, 105, 126, 0.08)"
                            v-if="$t(link.name) != 'Careers'"
                            class="pa-2"
                          >
                            <v-icon color="main">mdi-{{ child.img }}</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-title
                            class="caption d-flex flex-column mx-2"
                          >
                            <span class="font-weight-bold text-capitalize">{{
                              $tc(child.name)
                            }}</span>
                            <span
                              class="text--secondary mt-2"
                              v-if="child.description"
                              >{{
                                child.description
                                  ? breakText(child.description, 50)
                                  : child.description
                              }}</span
                            >
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                      </div>
                    </v-list>
                  </v-card>
                </v-menu>
                <span class="pb-0" v-else>{{ $tc(link.name) }}</span>
              </div>
            </v-hover>
          </a>
        </div>
        <div v-if="isLoggedIn">
          <v-menu
            offset-y
            close-on-click
            transition="slide-x-reverse-transition"
            min-width="250px"
            nudge-bottom="18"
            v-if="!$route.fullPath.includes('/profile/')"
          >
            <template v-slot:activator="{ on }">
              <v-list-item-avatar size="35" v-on="on" class="ml-3 mr-4 avatar">
                <v-img
                  :src="
                    currentUser.userImageUrl
                      ? currentUser.userImageUrl
                      : $store.state.profileImage
                  "
                  :lazy-src="$store.state.profileImage"
                ></v-img>
              </v-list-item-avatar>
            </template>
            <v-card flat class="menu-list card-outlined">
              <v-list dense class="pa-0 transparent">
                <v-list-item class="pa-2">
                  <v-list-item-avatar size="55" class="avatar">
                    <v-img
                      :src="
                        currentUser.userImageUrl
                          ? currentUser.userImageUrl
                          : $store.state.profileImage
                      "
                      :lazy-src="$store.state.profileImage"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-title class="caption d-flex flex-column mx-2">
                    <span class="text-uppercase">{{
                      currentUser.fullName
                    }}</span>
                    <span class="text--disabled caption">{{
                      currentUser.email
                    }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                  link
                  :to="{ name: 'Dashboard' }"
                  class="py-0"
                  dense
                  v-if="
                    currentUser.accessLevel != 5 || currentUser.accessLevel != 6
                  "
                >
                  <v-icon small left>mdi-link</v-icon>
                  <v-list-item-title class="caption d-flex flex-column mx-2">
                    <span class="">{{ $t("admin portal") }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <div v-for="(profile, i) in profiles" :key="i">
                  <v-list-item
                    link
                    @click="goTo(profile.route)"
                    class="py-0"
                    dense
                  >
                    <v-icon small left>mdi-{{ profile.icon }}</v-icon>
                    <v-list-item-title class="caption d-flex flex-column mx-2">
                      <span class="text-none">{{ profile.name }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </v-list>
            </v-card>
          </v-menu>
          <v-btn text v-else @click="loginLink()" title="Logout" class="pr-4">
            <v-icon color="grey darken-4">mdi-logout</v-icon>
          </v-btn>
        </div>
        <v-btn
          class="primary caption text-capitalize ml-3 mr-4"
          depressed
          small
          @click="loginLink()"
          v-else
        >
          <span>{{
            $vuetify.breakpoint.smAndDown
              ? $t("login")
              : `${$t("login")} | ${$t("register")}`
          }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import functions from "@/utilities/functions";
import LeftLowerMenu from "@/components/app/leftLowerMenu.vue";
import { mapGetters } from "vuex";
export default {
  name: "LowerAppBar",
  components: {
    LeftLowerMenu,
  },
  data() {
    return {
      enlarge: false,
      menu: false,
      profiles: [
        {
          name: this.$t("profile"),
          route: "My Details",
          icon: "account-circle",
        },
        { name: this.$t("log out"), route: "Logout", icon: "power" },
      ],
      search: {
        icon: true,
        value: "",
      },
    };
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      profileLinks: "webProfileLinks",
      mainLinks: "webMainLinks",
    }),
    links() {
      return this.$route.fullPath.includes("/profile/")
        ? this.profileLinks
        : this.mainLinks;
    },

    isLoggedIn() {
      return this.currentUser?.fullName ? true : false;
    },
  },
  methods: {
    ...functions,

    scroll(item, external) {
      // console.log(item);
      if (item.link && !external) {
        this.$router
          .push({
            name: item.link,
          })
          .then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else if (item.link && external) {
        window.open(item.link, "_blank");
      } else {
        if (this.$route.name == "Landing") {
          document.getElementById(item.id).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
          });
        } else {
          this.$router.push({ name: "Landing" }).then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
        }
        // this.checkRoute();
      }
    },
    redirect(link) {
      this.$router
        .push({
          name: link,
          params: {
            id: link?.routeParam || "",
          },
        })
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    async loginLink() {
      if (this.isLoggedIn) {
        await this.$store.dispatch("LOGOUT");
        this.$router.push({
          name: "Landing",
          params: { lang: this.$i18n.locale },
        });
      } else this.login();
    },

    checkRoute() {
      this.links.forEach((link) => {
        if (link.link !== null) {
          link.link == this.$route.name
            ? (link.active = true)
            : (link.active = false);
        } else {
          // var observer = new IntersectionObserver(
          //   function (entries) {
          //     entries.forEach((entry) => {
          //       entry.isIntersecting
          //         ? (link.active = true)
          //         : (link.active = false);
          //     });
          //   },
          //   { root: null, threshold: [0.2] }
          // );
          // observer.observe(document.getElementById(link.id));
        }
      });
    },
    goTo(link) {
      if (link == "My Details") {
        this.$router
          .push({
            name: link,
          })
          .then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.$store.dispatch("LOGOUT");
      }
    },
    login() {
      this.saveRedirectRoute({
        name: this.$route.name,
        params: this.$route.params,
      });
    },
  },
  mounted() {
    this.checkRoute();
  },
};
//
</script>
<style scoped>
.avatar {
  border: 1px solid #ddd;
}
</style>
