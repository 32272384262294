<template>
  <v-snackbar
    v-model="mySnackbar"
    bottom
    :right="!$vuetify.breakpoint.smAndDown"
    :color="color.text"
    :timeout="timeout"
  >
    <span class="caption">{{ payload.message }}</span>
    <template v-slot:action="{ attr }">
      <v-btn icon @click="sendBack()" :color="color.button" v-bind="attr">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: ["payload"],
  data: () => ({
    color: "",

    success: {
      text: "green lighten-5 success--text text--darken-3",
      button: "success darken-3",
    },
    error: {
      text: "red lighten-5 error--text text--darken-3",
      button: "error darken-3",
    },
    info: {
      text: "blue lighten-5 primary--text text--darken-3",
      button: "primary darken-3",
    },
    warning: {
      text: "warning lighten-5 warning--text text--darken-3",
      button: "warning darken-3",
    },
    timeout: 20000,
  }),
  computed: {
    mySnackbar: {
      get() {
        return this.payload.snackbar;
      },
      set(val) {
        return val;
      },
    },
  },

  watch: {
    mySnackbar() {
      switch (this.payload.type) {
        case "success":
          this.color = this.success;
          this.timeout = 4000;
          break;
        case "error":
          this.color = this.error;
          break;
        case "info":
          this.color = this.info;
          break;
        case "warning":
          this.color = this.warning;
          break;
      }

      setTimeout(() => {
        this.sendBack();
      }, 4000);
    },
  },

  methods: {
    sendBack() {
      this.$emit("snackbar");
    },
  },
};
</script>
