<template>
  <v-menu
    :close-on-content-click="false"
    max-height="300px"
    offset-x
    left
    transition="slide-x-reverse-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        class="notifications-button mb-8 mr-3 transition-fast-in-fast-out caption"
        fab
        right
        bottom
        color="appBarColor"
        elevation="21"
        fixed
        small
        :class="{ 'increase-margin-bottom': showGoToTop }"
      >
        <v-badge :content="2" color="primary" overlap class="caption">
          <v-icon color="lightColor"> mdi-bell </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card width="240px" height="300px" class="card-outlined menu-list pa-0">
      <v-card-title class="pa-3 caption">
        <span>Notifications</span>
      </v-card-title>
      <v-divider></v-divider>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ["showGoToTop"],
};
</script>

<style lang="css" scoped>
.notifications-button {
  --duration: 1s;
  --method: ease-in-out;
  transition: all var(--duration) var(--method) !important;
}
.increase-margin-bottom {
  transition: all var(--duration) var(--method) !important;
  bottom: 12svh !important;
}
</style>
