import axios from '@/plugins/axios/productsApi'

export default {
  GET_PRODUCTS: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get('allProducts', config)
        .then(res => {
          commit('SET_LOADER', false)
          resolve(res.data.products)
          // console.log(res)
        })
        .catch(err => {
          reject(err)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })
  },
  SINGLE_PRODUCT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`singleProduct/${payload}`, config)
        .then(res => {
          commit('SET_LOADER', false)
          resolve(res.data.product)
          // console.log(res)
        })
        .catch(err => {
          reject(err)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })
  },
  CREATE_PRODUCT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.post('createProduct', payload, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      // console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  UPDATE_PRODUCT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.put(
        `updateProduct/${payload.id}`,
        payload,
        config
      )
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      // console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  VERIFY_PRODUCT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.patch(
        `approveProduct/${payload.id}`,
        payload,
        config
      )
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  DELETE_PRODUCT: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.delete(`deleteProduct/${payload.id}`, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  }
}
