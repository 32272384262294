import i18n from "@/plugins/i18n";

export default {
  //general
  domain: "http://mijiminingapi.herokuapp.com/", //https://control-center-api.azurewebsites.net
  runApp: false,
  system_state_lock: false,
  system_under_maintenance: false,
  drawer: true,
  currency: "USD",
  exchangeRate: 116,
  loader: false,
  snackbar: {
    snackbar: false,
    message: "",
    type: "",
  },

  progress: {
    bool: false,
    value: 0,
  },

  //Setups
  app: "",
  countries: [],
  //authentication and settings
  currentUser: {
    status: null,
    token: "",
    access_token: "",
    response_data: {},
  },
  logo: "Jcc.png",
  flag: "ces.png",
  menuList: [],
  pages: [],
  languages: [
    {
      flag: "sa",
      lang: "ar",
      name: "arabic",
    },
    {
      flag: "us",
      lang: "en",
      name: "english",
    },
    {
      flag: "fr",
      lang: "fr",
      name: "french",
    },
  ],
  profileImage:
    "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=6&m=1223671392&s=170667a&w=0&h=zP3l7WJinOFaGb2i1F4g8IS2ylw0FlIaa6x3tP9sebU=",

  //reports

  //*****Justin B.******** */
  maxDateToSelect: new Date(),

  //********end***********
  appColor: "var(--appColor)",
  primaryColor: "var(--primaryColor)",
  darkGrey: "var(--darkGrey)",
  accentColor: "var(--accentColor)",

  //PRINTOUT TEMPLATES

  settings: "",

  topAppBar: true,
  currency: {
    name: "USD",
    sign: "$",
  },

  mobileDrawer: false,
  error: false,
  defaultNewsImg: "news.jpg",
  defaultResearchImg: "research.jpg",
  jobTypes: [],
  jobs: [],
  eventTypes: [],
  events: [],
  newsTypes: [],
  news: [],
  researches: [],
  singleNews: {},
  commodities: {},
  comment: {},
  categories: [],
  subCategories: [],
  sources: [],
  products: [],
  roles: [],
  users: [],
  countries: [],
  scraped: {
    radioTamazuj: [],
    topNews: [],
    sudanTribune: [],
    miningTechnology: [],
  },
  allNews: [],
  portalMenus: [
    {
      title: "Dashboard",
      icon: "mdi-monitor-dashboard",
      route: "",
      access: [0, 1, 2, 3, 4, 5],
    },
    {
      title: "Products",
      icon: "mdi-sitemap",
      route: "products",
      access: [0, 1, 3],
    },
    {
      title: "Jobs",
      icon: "mdi-briefcase",
      route: "jobs",
      access: [0, 1, 3],
    },
    {
      title: "Events",
      icon: "mdi-calendar-multiple",
      route: "events",
      access: [0, 1, 3],
    },
    {
      title: "Posts",
      icon: "mdi-note-plus",
      route: "posts",
      access: [0, 1, 2, 3, 4, 5],
    },
    {
      title: "Research & Journals",
      icon: "mdi-note-search",
      route: "researches",
      access: [0, 1, 2, 3, 4, 5],
    },
    {
      title: "Product Pricing",
      icon: "mdi-currency-usd",
      route: "productPricing",
      access: [0, 1, 3],
    },
    {
      title: "Settings",
      icon: "mdi-cog",
      access: [0, 1],
      children: [
        { title: "Users", route: "Users", access: [0, 1] },
        { title: "Subscribers", route: "Subscribers", access: [0, 1] },
        { title: "Categories", route: "Categories", access: [0, 1] },
        { title: "Sub Categories", route: "Sub Categories", access: [1] },
        { title: "Sources", route: "Sources", access: [0, 1] },
        // { title: "Roles", route: "" },
        { title: "Partners", route: "Partners", access: [0, 1] },
        { title: "Job Types", route: "Job Types", access: [0, 1] },
        { title: "Event Types", route: "Event Types", access: [0, 1] },
        { title: "Post Types", route: "Post Types", access: [0, 1] },
        // { title: "Teams", route: "" },
      ],
    },
  ],
  websiteMenus: {
    menus: [
      {
        name: "news & articles",
        id: "news",
        link: "newsView",
        active: false,
        submenu: false,
        icon: "newspaper-variant",
      },
      {
        name: "researches & journals",
        id: "analysis",
        link: "Researches & Journals",
        img: "search.png",
        active: false,
        icon: "note-search",
      },
      {
        name: "reports & maps",
        id: null,
        link: "Reports and Maps",
        img: "location.png",
        icon: "map",
        active: false,
      },
      {
        name: "cadastre portals",
        query: "mining-portals",
        id: "geologicalData",
        link: "Mining Cadastre Portals",
        img: "link-variant",
        icon: "link-variant",
        description: "",
        external: false,
      },
      {
        name: "SSMJ",
        id: "articles",
        active: false,
        submenu: true,
        icon: "clipboard-text-search",
        child: [
          {
            name: "about us",
            id: "about-us",
            link: "About Us",
            active: false,
            submenu: false,
            img: "information",
          },
          {
            name: "editorial board",
            id: "editorialTeam",
            link: "Editorial Team", //
            active: false,
            submenu: false,
            img: "account-multiple",
          },
          {
            name: "policy",
            id: "null",
            link: "Privacy", //
            active: false,
            submenu: false,
            img: "key-alert",
          },
          {
            name: "adminPortal.drawer.investor guide",
            id: "null",
            link: "Investor Guide Doc", //
            active: false,
            submenu: false,
            img: "handshake",
          },
        ],
      },
    ],
    profile: [
      {
        name: "home",
        link: "Landing",
        active: false,
      },
      {
        name: "dashboard",
        link: "My Dashboard",
        active: false,
      },
      {
        name: "news & articles",
        link: "My News",
        active: false,
      },
      {
        name: "researches & journals",
        link: "My Researches",
        active: false,
      },
      {
        name: "my events",
        link: "My Events",
        active: false,
      },
      {
        name: "my details",
        link: "My Details",
        active: false,
      },
    ],
    redirect: {
      name: "",
      params: "",
    },
  },
};
