<template>
  <div class="scrolled" id="contactCard">
    <v-card
      width="92%"
      style="position: absolute; top: -200px; left: 0; right: 0; margin: 0 auto"
      light
    >
      <v-layout row wrap class="my-auto" fill-height>
        <v-flex
          xs12
          sm12
          md6
          lg6
          class="py-4 px-12 d-none d-md-flex d-lg-flex d-xl-flex flex-column primary"
        >
          <v-card tile flat dark class="transparent">
            <TitleModel :title="$t('call us')" class="mb-3" />

            <v-list dense class="pa-0 mt-8 transparent" flat>
              <v-list-item
                class="caption pa-0 mt-2 white--text px-1"
                dense
                v-for="(contact, i) in contacts"
                :key="i"
                @click="contactUs(contact)"
                :link="contact.type !== 'location'"
              >
                <v-list-item-icon>
                  <v-btn icon small class="secondary disabled-btn" dark>
                    <v-icon x-small>{{ contact.icon }}</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-title class="text-break mx-2">
                  <span
                    :dir="
                      contact.type == 'tel'
                        ? 'ltr'
                        : $store.getters.getTextDirection
                    "
                  >
                    {{ contact.text }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
          <!-- <v-btn small class="caption text-capitalize primary mt-4">
            <span>Contact us now</span>
          </v-btn> -->
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 class="py-4 px-12" style="position: relative">
          <div class="bg-card1"></div>

          <TitleModel :title="$t(`quick contact`)" />
          <v-form ref="quickContactForm" class="py-3">
            <v-text-field
              dense
              :placeholder="$t('name')"
              class="caption mt-2"
              v-model="contact.fullName"
              :rules="requiredRule"
              @keypress.enter.prevent="addContact()"
            ></v-text-field>
            <v-text-field
              dense
              :placeholder="$t('email')"
              class="caption mt-2"
              v-model="contact.email"
              :rules="requiredRule"
              @keypress.enter.prevent="addContact()"
            ></v-text-field>
            <v-text-field
              dense
              :placeholder="$t('phone')"
              class="caption mt-2"
              v-model="contact.phone"
              :rules="requiredRule"
              @keypress.enter.prevent="addContact()"
            ></v-text-field>
            <v-textarea
              class="caption"
              :placeholder="$t('how can we help you')"
              rows="3"
              v-model="contact.enquiry"
              :rules="requiredRule"
              @keypress.enter.prevent="addContact()"
            ></v-textarea>
            <v-btn
              small
              class="caption text-capitalize primary mt-4"
              @click="addContact()"
              :loading="$store.getters.getLoader"
              block
            >
              <span>{{ $t("contact us now") }}</span>
            </v-btn>
          </v-form>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import TitleModel from "../core/titleModel.vue";
export default {
  name: "ContactCard",
  components: { TitleModel },
  data() {
    return {
      contacts: [
        {
          text: "ssmj@lilicoengineering.com",
          icon: "mdi-email",
          link: "ssmj@lilicoengineering.com",
          type: "email",
        },
        {
          text: "+211 912 325 210",
          icon: "mdi-phone",
          link: "+211912325210",
          type: "tel",
        },
        {
          text: "Hai Jalaba, Juba Mall, 2nd floor, Suite #3, Juba-South Sudan",
          icon: "mdi-map-marker",
          link: "",
          type: "location",
        },
      ],
      contact: {
        fullName: "",
        email: "",
        phone: "",
        enquiry: "",
      },
      requiredRule: [(v) => !!v || "Required"],
    };
  },
  methods: {
    contactUs(contact) {
      if (contact.type == "email") {
        let a = document.createElement("a");
        a.href = `mailto:${contact.link}?subject=INQUIRIES`;
        a.dispatchEvent(new MouseEvent("click"));
      } else if (contact.type == "tel") {
        let a = document.createElement("a");
        a.href = `tel:${contact.link}`;
        a.dispatchEvent(new MouseEvent("click"));
      }
    },
    async addContact() {
      if (this.$refs.quickContactForm.validate()) {
        await this.$store.dispatch("QUICK_CONTACT", this.contact).then(() => {
          if (!this.$store.getters.getError) {
            this.$refs.quickContactForm.reset();
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.bg-card1 {
  background-image: url(../../../public/images/Group-1.png);
  background-position: center;
  background-size: contain;
  opacity: 0.28;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.text-break {
  white-space: normal;
}
</style>
