import axios from '@/plugins/axios/jobsAPI'

export default {
  GET_JOBS: async ({ commit, getters }) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get('allJobs', config)
        .then(res => {
          commit('SET_LOADER', false)
          resolve(res.data.jobs)
          // console.log(res)
        })
        .catch(err => {
          reject(err)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })

  },
  SINGLE_JOB: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`singleJob/${payload}`, config)
        .then(res => {
          commit('SET_LOADER', false)
          resolve(res.data.job)
          // console.log(res)
        })
        .catch(err => {
          reject(err)
          commit('SET_LOADER', false)
          commit('SET_ERROR', true)
        })
    })

  },
  CREATE_JOB: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.post('createJob', payload, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  UPDATE_JOB: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.put(`updateJob/${payload.id}`, payload, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  VERIFY_JOB: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.patch(`approveJob/${payload.id}`, payload, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  },
  DELETE_JOB: async ({ commit, getters }, payload) => {
    commit('SET_ERROR', false)
    commit('SET_LOADER', true)
    var config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getters.getToken
      }
    }
    try {
      let response = await axios.delete(`deleteJob/${payload.id}`, config)
      commit('SET_LOADER', false)
      commit('SNACKBAR', {
        snackbar: true,
        message: response.data.message,
        type: 'success'
      })
      console.log(response)
    } catch (error) {
      commit('SET_LOADER', false)
      commit('SET_ERROR', true)
      commit('SNACKBAR', {
        snackbar: true,
        message: error.response.data.message,
        type: 'error'
      })
      console.log(error)
    }
  }
}
