import axios from "@/plugins/axios/investorGuideApi";

export default {
  GET_INVESTOR_GUIDES: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("showInvestorGuide", config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.investorGuides);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  SINGLE_INVESTOR_GUIDE: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`singleInvestorGuide/${payload}`, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.investorGuide);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  GET_INVESTOR_GUIDE_DOC: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`pdf`, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data.documentPath);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },

  CREATE_INVESTOR_GUIDE: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.post(
        "createInvestorGuide",
        payload.investorguide,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error?.response?.data?.message || error,
        type: "error",
      });
      console.log(error);
    }
  },
  UPDATE_INVESTOR_GUIDE: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.put(
        `updateInvestorGuide/${payload.id}`,
        payload.investorguide,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.message,
        type: "error",
      });
      console.log(error);
    }
  },
  DELETE_INVESTOR_GUIDE: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.delete(
        `deleteInvestorGuide/${payload.id}`,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
};
