<template>
  <div>
    <div v-if="maintenance">
      <maintenance />
    </div>
    <div class="" v-else>
      <home-template />
    </div>
  </div>
</template>

<script>
import HomeTemplate from "../components/home-template.vue";
import Maintenance from "../components/maintenance.vue";
export default {
  name: "HomeView",

  components: { Maintenance, HomeTemplate },
  data() {
    return {
      maintenance: false,
    };
  },
};
</script>
