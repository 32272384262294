import axios from "@/plugins/axios/auth";

export default {
  CREATE_ACCOUNT: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    try {
      let response = await axios.post("signup", payload);
      commit("SNACKBAR", {
        snackbar: true,
        message: "Account created successfully",
        type: "success",
      });
      commit("SET_LOADER", false);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },

  LOGIN: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    try {
      let response = await axios.post("login", payload);
      if (response.data.status == "success") {
        commit("SNACKBAR", {
          snackbar: true,
          message: "Access Granted",
          type: "success",
        });
        commit("SET_LOADER", false);
        commit("SET_ACCESS_TOKEN", response.data.token);
        commit("SET_SESSION", response.data.data.user);
      } else {
        commit("SNACKBAR", {
          snackbar: true,
          message: "Access Denied",
          type: "error",
        });
      }
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },

  LOGOUT: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.get("logout", config);
      commit("SET_ACCESS_TOKEN", null);
      commit("SET_SESSION", null);
      commit("SET_LOADER", false);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },

  CREATE_USER: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.post("addUser", payload, config);
      commit("SNACKBAR", {
        snackbar: true,
        message: "Account created successfully",
        type: "success",
      });
      console.log(response);
      commit("SET_LOADER", false);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },

  GET_USERS: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("allUsers", config)
        .then((response) => {
          commit("SET_LOADER", false);
          resolve(response.data.users);
        })
        .catch((err) => {
          reject(err.response?.data.message || err.message);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  GET_REVIEWERS: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("userReviewers", config)
        .then((response) => {
          commit("SET_LOADER", false);
          resolve(response.data.users);
        })
        .catch((err) => {
          reject(err.response?.data.message || err.message);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
  SINGLE_USER: async ({ commit, getters }, id) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`singleUser/${id}`, config)
        .then((response) => {
          commit("SET_LOADER", false);
          resolve(response.data.user);
        })
        .catch((err) => {
          reject(err.response?.data.message || err.message);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },

  GET_CURRENT_USER: async ({ commit, getters }) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("currentUser", config)
        .then((response) => {
          commit("SET_LOADER", false);
          resolve(response.data.users);
        })
        .catch((err) => {
          reject(err.response?.data.message || err.message);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },

  UPDATE_USER: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.put(
        `updateUser/${payload.id}`,
        payload,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  UPDATE_PASSWORD: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.patch(
        `updatePassword/${payload.id}`,
        payload,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },

  DELETE_USER: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.delete(`deleteUser/${payload}`, config);
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  VERIFY_USER: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.patch(
        `verifyResearcher/${payload.id}`,
        payload,
        config
      );
      commit("SET_LOADER", false);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  FORGOT_PASSWORD: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.post("forgotPassword", payload, config);
      commit("SNACKBAR", {
        snackbar: true,
        message: response.message ?? response.data.message,
        type: "success",
      });
      console.log(response);
      commit("SET_LOADER", false);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  RESET_PASSWORD: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.put(
        `resetPassword/${payload.token}`,
        payload.passwords,
        config
      );
      commit("SNACKBAR", {
        snackbar: true,
        message:
          response.message ??
          response.data.message ??
          "New password created successfully",
        type: "success",
      });
      console.log(response);
      commit("SET_LOADER", false);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  RESEND_EMAIL_ACTIVATION: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.post(
        `resendEmailActivation/`,
        payload,
        config
      );
      commit("SNACKBAR", {
        snackbar: true,
        message:
          response.message ??
          response.data.message ??
          "New password created successfully",
        type: "success",
      });
      console.log(response);
      commit("SET_LOADER", false);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
  VERIFY_TOKEN: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("activateEmail", payload, config)
        .then((response) => {
          commit("SET_LOADER", false);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response?.data.message || err.message);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },

  VERIFY_ADMIN: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    try {
      let response = await axios.get(`token-verification?token=${payload}`);
      commit("SET_LOADER", false);
      return Promise.resolve(response?.data.user);
    } catch (error) {
      commit("SET_LOADER", false);
      commit("SET_ERROR", true);
      commit("SNACKBAR", {
        snackbar: true,
        message: error.response?.data.message,
        type: "error",
      });
      console.log(error);
    }
  },
};
