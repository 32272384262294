import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import themes from "./theme";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: { dark: false, themes },
});
