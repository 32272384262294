<template>
  <div class="">
    <v-layout
      row
      wrap
      class="ma-auto"
      :class="$vuetify.breakpoint.mdAndUp ? 'px-8' : 'px-4'"
      fill-height
    >
      <div class="bg2"></div>
      <v-flex
        xs12
        sm12
        md4
        lg4
        xl4
        :class="$vuetify.breakpoint.smAndDown ? 'pt-0 pb-4' : 'py-4'"
      >
        <v-img src="/Frame2.png" width="220" class="mb-4"></v-img>
        <div class="caption">
          <span
            class="font-weight-light text-justify"
            :dir="$store.getters.getTextDirection"
            >{{ $t("system description") }}</span
          >
        </div>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex
        xs6
        sm6
        md2
        lg2
        xl2
        class="px-0 py-2"
        style="z-index: 1 !important"
        v-for="(content, i) in footerData"
        :key="i"
      >
        <!-- <TitleModel :title="content.name" /> -->
        <div class="subtitle-2 left-title">
          <strong class="main-post-title text-uppercase"
            >{{ content.name }}
            <hr class="cat-post-title" />
          </strong>
        </div>
        <div class="d-flex flex-column mt-4 caption text-capitalize">
          <span
            style="text-decoration: none"
            class="white--texttext-capitalize py-2 link"
            @click="redirect(val.route)"
            v-for="(val, j) in content.value"
            :key="j"
            >{{ val.title }}</span
          >
        </div>
      </v-flex>
      <v-flex xs12 sm12 md3 lg3 xl3 class="px-0 py-2">
        <div class="subtitle-2 left-title">
          <strong class="main-post-title text-uppercase"
            >{{ $t("newsletters") }}
            <hr class="cat-post-title" />
          </strong>
        </div>
        <div class="mt-5 caption">
          <span
            class="font-weight-light text-justify"
            :dir="$store.getters.getTextDirection"
            >{{ $t("newsletter text") }}</span
          >
          <v-form ref="newsLetterForm" class="mt-4" style="position: relative">
            <v-text-field
              :placeholder="$t('fields.email')"
              background-color="white"
              color="primary"
              outlined
              dense
              class="caption"
              light
              v-model="email"
              :rules="emailRule"
              prepend-inner-icon="mdi-email"
              hide-details
            ></v-text-field>
            <v-btn
              x-small
              class="caption text-capitalize btn rounded-sm"
              depressed
              :loading="$store.getters.getLoader"
              @click="subscribe"
              color="main"
              fab
              :style="$i18n.locale == 'ar' ? 'left:1.5px' : 'right:1.5px'"
            >
              <v-icon>mdi-send-variant</v-icon>
            </v-btn>
          </v-form>
        </div>
        <div class="caption d-flex flex-column align-start mt-4">
          <span
            class="font-weight-light text-justify"
            :dir="$store.getters.getTextDirection"
            >{{ $t("newsletter subtitle") }}</span
          >
          <Social :dark="false" class="ml-n3" />
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import TitleModel from "../core/titleModel.vue";
import Social from "../models/social.vue";
import singleDataGetters from "@/utilities/singleDataGetters";
import functions from "@/utilities/functions";
export default {
  components: { TitleModel, Social },
  name: "FooterDescription",
  data() {
    return {
      newsLetterForm: false,
      footerData: [
        {
          name: this.$t("company"),
          value: [
            { title: this.$t("about us"), route: "About Us" },
            { title: this.$tc("partner", 2), route: "All Partners" },
            { title: this.$t("media"), route: "Media" },
            { title: this.$tc("career", 2), route: "Careers" },
          ],
        },

        {
          name: this.$tc("download", 2),
          value: [
            { title: this.$tc("news", 2), route: "newsView" },
            { title: this.$tc("article", 2), route: "All News" },
            {
              title: this.$tc("researches & journals", 2),
              route: "Researches & Journals",
            },
            { title: this.$tc("market", 2), route: "marketView" },
          ],
        },
      ],
      email: "",
      emailRule: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "Email not valid",
      ],
    };
  },
  computed: {
    publishedArticles() {
      return this.articles
        .sort((a, b) => (a.datePublished < b.datePublished ? 1 : -1))
        .slice(0, 3);
    },
  },
  methods: {
    ...singleDataGetters,
    ...functions,

    redirect(route) {
      // var reg = / /g;
      route === "All News"
        ? this.$router
            .push({ name: route, params: { filter: "articles" } })
            .then(() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            })
        : this.$router.push({ name: route }).then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },

    async getData() {
      this.articles = await this.$store.dispatch("GET_PUBLISHED_ARTICLES");
    },
    async subscribe() {
      if (this.$refs.newsLetterForm.validate()) {
        await this.$store.dispatch("SUBSCRIBE", { email: this.email });
      }
    },
  },
  created() {
    // this.getData();
  },
};
</script>

<style scoped>
.main-post-title {
  font-size: 1.1rem;
}
.btn {
  position: absolute;
  top: 1.5px;
}
.bg2 {
  background-image: url(../../../public/images/Group\ 1423.png);
  background-position: center;
  background-size: contain;
  width: 40%;
  height: 40%;
  bottom: 10vh;
  right: 0;
  left: 0;
  margin: 0 auto;
  opacity: 0.5;
  position: absolute;
  z-index: 0;
}
.link {
  cursor: pointer !important;
}
</style>
