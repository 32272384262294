import userActions from "./actions/authActions";
import jobTypesActions from "./actions/jobTypesActions";
import jobActions from "./actions/jobActions";
import eventActions from "./actions/eventActions";
import eventTypeActions from "./actions/eventTypeActions";
import newsActions from "./actions/newsActions";
import newsTypeActions from "./actions/newsTypeActions";
import categoryActions from "./actions/categoryActions";
import subCategoryActions from "./actions/subCategoryActions";
import sourceAction from "./actions/sourceAction";
import productActions from "./actions/productActions";
import commodityPricingAction from "./actions/commodityPricingAction";
import roleActions from "./actions/roleActions";
import commentActions from "./actions/commentActions";
import researchActions from "./actions/researchActions";
import scrapedNewsActions from "./actions/scrapedNewsActions";
import partnerActions from "./actions/partnerActions";
import contactActions from "./actions/contactActions";
import videoActions from "./actions/videoActions";
import subscriberActions from "./actions/subscriberActions";
import galleryActions from "./actions/galleryActions";
import professionActions from "./actions/professionActions";
import reviewCommentActions from "./actions/reviewCommentActions";
import pdfActions from "./actions/pdfActions";
import policyActions from "./actions/policyActions";
import investorGuideAction from "./actions/investorGuideAction";
import teamActions from "./actions/teamActions";
import mapActions from "./actions/mapActions";
import mapCategories from "./actions/mapCategories";
export default {
  ...newsActions,
  ...userActions,
  ...jobTypesActions,
  ...jobActions,
  ...eventTypeActions,
  ...eventActions,
  ...newsTypeActions,
  ...categoryActions,
  ...productActions,
  ...commodityPricingAction,
  ...roleActions,
  ...researchActions,
  ...scrapedNewsActions,
  ...sourceAction,
  ...subCategoryActions,
  ...partnerActions,
  ...commentActions,
  ...contactActions,
  ...videoActions,
  ...subscriberActions,
  ...galleryActions,
  ...professionActions,
  ...reviewCommentActions,
  ...pdfActions,
  ...policyActions,
  ...investorGuideAction,
  ...teamActions,
  ...mapActions,
  ...mapCategories,
};
