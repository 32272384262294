<template>
  <div class="d-flex align-center">
    <v-btn
      :href="social.link"
      target="_blank"
      icon
      v-for="(social, i) in socialMedia"
      :key="i"
      :dark="dark"
      :small="!large"
    >
      <!-- <v-icon :small="!large">{{ social.icon }}</v-icon> -->
      <Icon :icon="social.icon" width="15" />
    </v-btn>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
  props: {
    dark: {
      type: Boolean,
      default: () => true,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  components: { Icon },
  data() {
    return {
      socialMedia: [
        {
          icon: "line-md:twitter-x",
          link:
            this.$i18n.locale !== "ar"
              ? "https://twitter.com/SSudanMJ"
              : "https://twitter.com/SSudanMJ_AR",
          color: "#00acee",
        },
        // { icon: "mdi:instagram", link: "", color: "#F56040" },
        {
          icon: "line-md:linkedin",
          link:
            this.$i18n.locale !== "ar"
              ? "https://www.linkedin.com/company/south-sudan-mining-journal"
              : "https://www.linkedin.com/company/south-sudan-mining-journal-ar",
          color: "#0072b1",
        },
        {
          icon: "line-md:facebook",
          link:
            this.$i18n.locale !== "ar"
              ? "https://www.facebook.com/SSMiningJournal"
              : "https://www.facebook.com/profile.php?id=61553914561964",
          color: "#3b5998",
        },
        {
          icon: "mdi:youtube",
          link: "https://www.youtube.com/channel/UCvEwgimvXlJEKmjaZAP8WtQ",
          color: "#c4302b",
        },
      ],
    };
  },
};
</script>

<style></style>
