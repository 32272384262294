import store from "../store";
import systemMenus from "@/assets/static/menu.json";

export default {
  goTo: (to, from, next) => {
    if (store.getters.getToken) {
      let user = store.getters.getCurrentUser;
      let menu = systemMenus.find((i) =>
        i.children
          ? i.children.find((c) => c.title == to.name)
          : i.title == to.name
      );
      let userMmenus = systemMenus.filter((i) =>
        i.access.includes(user.accessLevel)
      );
      // console.log(userMmenus);
      if ([0, 1].includes(user.accessLevel)) next();
      else if (menu) {
        if (!menu.access.includes(user.accessLevel)) {
          if (userMmenus.length == 0) {
            next({ name: "Login" });
          } else {
            next({ name: userMmenus[0].title });
          }
        } else next();
      } else {
        // alert('Page not yet Accessible\nContact administrator to get permissions, here')
        next();
      }
    } else {
      next({ name: "Login" });
      setTimeout(() => {
        store.commit("SNACKBAR", {
          snackbar: true,
          type: "error",
          message: "Access denied, Please login",
        });
      }, 1000);
    }
  },
};
