import axios from "@/plugins/axios/pdfApi";

export default {
  GET_RESEARCH_PDF: async ({ commit, getters }, payload) => {
    commit("SET_ERROR", false);
    commit("SET_LOADER", true);
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getters.getToken,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`${payload.type}/${payload.id}`, config)
        .then((res) => {
          commit("SET_LOADER", false);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          commit("SET_LOADER", false);
          commit("SET_ERROR", true);
        });
    });
  },
};
