<template>
  <v-menu offset-y v-model="menuLang">
    <template v-slot:activator="{ on }">
      <v-btn
        text
        v-on="on"
        style="cursor: pointer"
        class="caption text-capitalize"
        small
        title="Language"
      >
        <flag :iso="getFlag($i18n.locale)" :squared="false" />
        <span class="mx-2">{{
          $t(`languages.${getLangName($i18n.locale)}`)
        }}</span>
        <v-icon end small>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list dense max-height="300" class="overflow-y-auto pa-0" tile>
      <div v-for="item in languages" :key="item.lang">
        <v-list-item
          @click="switchLanguage(item.lang)"
          :value="item.lang"
          :class="{ 'active-lang': item.lang == $i18n.locale }"
        >
          <v-list-item-content>
            <v-list-item-title class="caption text-capitalize">
              <flag :iso="item.flag" :squared="false" />
              <span class="mx-2">{{ $t(`languages.${item.name}`) }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    menuLang: false,
    languages: [
      {
        flag: "sa",
        lang: "ar",
        name: "arabic",
      },
      {
        flag: "us",
        lang: "en",
        name: "english",
      },
      {
        flag: "fr",
        lang: "fr",
        name: "french",
      },
    ],
  }),
  methods: {
    getLangName(lang) {
      return this.$store.state.languages.find((l) => l.lang == lang).name;
    },
    getFlag(lang) {
      return this.$store.state.languages.find((l) => l.lang == lang).flag;
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push({
        params: { lang: lang },
      });
      setTimeout(() => window.location.reload(), 500);
    },
  },
};
</script>
<style scoped>
.active-lang {
  background-color: #136c7e32;
  color: #136c7e !important;
}
</style>
